@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');

.signika-font-aboutUs {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
}

.architects-owner-name {
  font-family: "Signika", sans-serif;
  font-weight: 800;
  color: #233e2d
}

.our-founder {
  font-size: 28px;
  font-family: "Signika", sans-serif;
}

.signika-font-Architects {
  font-family: "Signika", sans-serif;
  font-size: 15px;
  text-align: justify;
}

.Architects-page-containter {
  background-image: url(../../../assets/images/Architects/BG.png);
  width: 100%;
  height: auto;
}

.Architects-header {
  background-color: #134b2b;
  color: #fff;
  height: 70px;
}

@media (max-width: 1440px) {
  .Architects-header {
    margin-top: 5% !important;
    /* Adjusted the value to avoid negative margin */
  }
}

@media (max-width: 1024px) {
  .Architects-header {
    margin-top: 7% !important;
    /* Adjusted the value to avoid negative margin */
  }
}

@media (max-width: 768px) {
  .Architects-header {
    margin-top: 10% !important;
    /* Use this for smaller screens */
  }
}

@media (max-width: 480px) {
  .Architects-header {
    margin-top: 15% !important;
    /* Further adjustments for very small screens */
  }
}

.architects-owner-image {
  width: 350px;
  height: 600px;
}

.architects-owner-image-container {
  position: relative;
}

.architects-owner-image-container::after {
  content: "";
  height: 840px;
  position: absolute;
  width: 50px;
  top: 81%;
  background-color: #134b2b;
  z-index: -3;
}

@media (min-width: 1700px) {
  .architects-owner-image-container::after {
    height: 800px;
  }
}

@media (min-width: 993px) and (max-width: 1111px) {
  .architects-owner-image {
    width: 270px !important;
  }

  .architects-owner-image-container::before {
    content: "";
    height: 267px !important ;
    bottom: 72% !important;
    z-index: -2 !important;
  }
  .architects-owner-image-container::after {
    content: "";
    height: 1025px !important;
    top: 70% !important;
}
}

@media (min-width: 990px) and (max-width: 992px) { 
  .architects-owner-image-container::before {
    content: "";
    height: 0px !important ;
  }
  .architects-owner-image-container::after {
    content: "";
    height: 0px !important;
}
 }

.architects-owner-image-container::before {
  content: "";
  height: 165px;
  position: absolute;
  width: 50px;
  bottom: 82%;
  background-color: #134b2b;
}

@media screen and (min-width:0px) and (max-width: 991px) {
  .architects-owner-image-container::before {
    display: none;
  }

  .architects-owner-image-container::after {
    display: none;
  }

  .architects-owner-image {
    width: 350px;
    height: 320px;
  }
}

@media (min-width: 992px) {
  .overallPaddingForArchitect {
      padding: 0px 75px !important;
  }
}