.spotlightHomepage-background-color {
  background-color: #385623 !important;
}

.Spot-light-header {
  color: #ffff
}

.Spot-light-header-border {
  border: 4x solid white !important;
  border-radius: 50%;
}

.arrowConfig-sportlight {
  border: 1px solid #ffff;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  margin-left: 6px;
}

.Announcement-home-page-card {
  height: 435px;
}

.announcements-card-homepage-text {
  color: black;
  border-bottom: 2px solid black;
}

/* For Webkit browsers (Chrome, Safari) */
#announcement-container::-webkit-scrollbar {
  width: 0px;
  /* Hides the scrollbar */
}

#announcement-container::-webkit-scrollbar-thumb {
  background: transparent;
  /* Hides the thumb (scroll part) */
}

.backgroud-sportlight-Text {
  background-color: #eef0e9;
  height: 80px;
}

.sportlight-Text-text-color {
  color: #104e29;
}

.sportlight-content {
  font-size: 15px !important;
  text-align: justify;
}

.annocement-text {
  font-size: 17px !important;
  text-align: justify;
  padding-right: 25px;
}

.spot-light-button {
  background-color: #104e29 !important;
  color: #ffff !important
}

.sportlight-announcement-div-container {
  height: 500px;
}

.spotlight_txt {
  text-align: justify;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .spotlight-announcementcontainer {
    width: 97%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .spotlight-announcementcontainer {
    width: 97%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1729px) and (max-width: 2000px) {
  .Announcement-home-page-card {
    height: 398px;
  }
}

@media screen and (min-width: 1413px) and (max-width: 1730px) {
  .Announcement-home-page-card {
    height: 420px;
  }
}

@media screen and (min-width: 1198px) and (max-width: 1414px) {
  .Announcement-home-page-card {
    height: 445px;
  }
}

@media screen and (min-width: 1078px) and (max-width: 1199px) {
  .Announcement-home-page-card {
    height: 468px;
  }
}

@media screen and (min-width: 1009px) and (max-width: 1079px) {
  .Announcement-home-page-card {
    height: 492px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1010px) {
  .Announcement-home-page-card {
    height: 515px;
  }
}

@media screen and (min-width: 767px) and (max-width: 909px) {
  .sportlight-announcement-div-container {
    margin-bottom: 15px;
  }
}

.announcementImg img {
  height: 50%;
  width: 100%;
}

.spotlight-content {
  height: 116px;
}

@media (max-width: 1079px) {
  .spotlight-content {
    height: 166px;
  }
}

@media (min-width:1080px) and (max-width: 1198px) {
  .spotlight-content {
    height: 142px;
  }
}

@media (min-width:1199px) and (max-width: 1339px) {
  .spotlight-content {
    height: 120px;
  }
}

@media (min-width:1416px) and (max-width: 1730px) {
  .spotlight-content {
    height: 94px;
  }
}

@media (min-width:1731px) and (max-width: 2002px) {
  .spotlight-content {
    height: 74px;
  }
}

@media (min-width:2001px) {
  .spotlight-content {
    height: 112px;
  }
}

@media (min-width:993px) and (max-width: 1011px) {
  .spotlight-content {
    height: 191px;
  }
}


@media (min-width:560px) and (max-width: 767px) {
  .spotlight-content {
    height: 116px;
  }
}