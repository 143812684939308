.custom-container {
  padding-left: 4rem;
  padding-right: 4rem;
}

.custom-header {
  font-family: 'Signika', sans-serif;
  color: #104e29;
  font-weight: bold;
  margin-top: 20px;
  white-space: nowrap;
}

.announcement-hr {
  border: none;
  height: 2px;
  background-color: #7a1316;
  width: 100%;
  margin-top: 25px;
}

.custom-img {
  border-radius: 50%;
  padding: 5px;
  font-size: 45px;
  color: #ffffff;
  z-index: 1;
}

.custom-span {
  background-color: #288d51;
  color: #ffffff;
  font-size: 15px;
  padding: 6px 35px;
  border-radius: 62px;
  margin-left: -30px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.custom-card {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.custom-card:hover {
  transform: scale(1.05);
}

.custom-card-title0 {
  color: #104e29;
  text-align: jusify;
  font-weight: bold;
}

.custom-card-title1 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.custom-card-title2 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.custom-card-title3 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.custom-card-title-image {
  width: 20px;
  /* Adjust this based on actual image size */
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.custom-img {
  border-radius: 50%;
  padding: 5px;
  font-size: 45px;
  color: #ffffff;
  z-index: 1;
}

.custom-span {
  background-color: #288d51;
  color: #ffffff;
  font-size: 15px;
  padding: 6px 35px;
  border-radius: 62px;
  margin-left: -30px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media (max-width: 575.98px) { 
  .custom-span {
  padding: 6px 19px;
  }
 }

.announce-card {
  width: 88%;
  height: 100%;
  background-color: white !important;
}

.announce-card-title {
  text-align: center;
}

.announce-card-text {
  text-align: center;

}

.announce-card-title0 {
  color: #104e29;
  text-align: jusify;
  font-weight: bold;
}

.announce-card-title1 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.announce-card-title2 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.announce-card-title3 {
  color: #104e29;
  text-align: justify;
  font-weight: bold;
}

.announce-card-title-image {
  width: 20px;
  /* Adjust this based on actual image size */
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.announce-container {
  background-color: #eaeaea;
  border-radius: 5px;
  width: 80%;
}