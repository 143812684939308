.upcoming-custom-card {
  height: 400px;
  width: 300px;
  border-radius: 20% !important;
  overflow: visible !important;
  transition: transform 0.5s ease;
  position: relative;
  padding: 5px;
  border: 2px dotted #ece1e1 !important;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .upcoming-custom-card {
    width: 262px;
  }

  .date-time-container {
    padding: 5px 0px !important;
  }

  .overlay-card {
    width: 130% !important;
  }
}
.upcoming-custom-card::before {
  content: '';
  position: absolute;
  top: -5px;   /* Adjust distance here */
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px dotted  #0f4a27; /* Set the border color here */
  border-radius:20%;
  opacity: 0;
  /* transition: opacity 0.3s ease, transform 0.3s ease; */
  transform: scale(0.95); /* Slightly smaller initially */
}

.upcoming-custom-card:hover::before {
  opacity: 1;
  transform: scale(1.05); /* Expands outward */
}
/* .upcoming-custom-card:hover {
  transform: scale(1.05);
  border: 2px dotted #0f4a27 !important;
  padding:25px;
} */

.upcoming-card-body {
  border-radius: 20% !important;
  overflow: visible;
}

.overlay-card {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140%;
  height: 40%;
  z-index: 2;
  background-color: #dbdbdb;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 0 0 2px rgba(105, 104, 104, 0.5);
  overflow: visible;
}

@media (max-width: 575.98px) {
  .overlay-card {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    height: 40%;
    z-index: 2;
    background-color: white;
    border-radius: 30px;
    text-align: center;
    box-shadow: none;
    overflow: visible;
  }
}

.overlay-card-image {
  width: 100%;
  height: 160px;
  object-fit: fill;
  border-radius: 25px;
}

.date-time-container {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
  background-color: rgba(245, 241, 241, 0.9);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
}

@media (max-width: 575.98px) {
  .date-time-container {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    background-color: rgba(245, 241, 241, 0.9);
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    white-space: nowrap;
  }

  .upcoming-custom-card {
    width: 242px !important;
  }

  .overlay-card-image {
    border-radius: 0px !important;
  }
}

.date-container {
  color: black;
  font-size: 1rem;
}

.time-container {
  color: #0f4a27;
  font-size: 1rem;
  background-color: #d9d9d9 !important;
}

.organize-class {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  background-color: rgba(245, 241, 241, 0.9);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
}

.role-class {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  background-color: rgba(245, 241, 241, 0.9);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.viewmore-class {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
}

.viewMore-event-btn {
  background-color: #0f4a27 !important;
}

.viewMore-event-btn:hover {
  background-color: #803c41 !important;
}

.upcoming-custom-card:hover .viewMore-event-btn {
  background-color: #803c41 !important;
}

/* Default state for viewmore image */
.viewmore-img {
  content: url("../../assets/images/Event/viewmore.png");
  transition: content 0.3s ease-in-out;
  cursor: pointer;
}

/* Hover state for viewmore image */
.upcoming-custom-card:hover .viewmore-img {
  content: url("../../assets/images/Event/viewmoreGreen.png");
}

.leftarrow-class,
.rightarrow-class {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
}

.leftarrow-class {
  left: -40px;
}

.rightarrow-class {
  right: -40px;
}

@media (max-width: 575.98px) {
  .leftarrow-class {
    left: -10px;
  }

  .rightarrow-class {
    right: -10px;
  }

  .leftarrow-class,
  .rightarrow-class {
    top: 50%;
  }
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-card {
  min-width: 33.33%;
  box-sizing: border-box;
  overflow: visible;
}

.carousel-class {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (min-width: 767px) and (max-width: 769px) {
  .rightarrow-class {
    right: -28px;
  }

  .leftarrow-class {
    left: -30px;
  }
}

@media screen and (min-width: 280px) and (max-width: 767px) {}

.events{
  background-color: #d2dcc6;
  flex-wrap: wrap;
}

.ecard{
  width: 82%;
  max-width: 400px;
  min-width: 200px;
  background-color: white;
  /* margin: 0px 78px; */
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}

.ecard img{
  width: 100%;
}

/* .date{
  position: absolute;
}

.lable{
  position: absolute; 
} */

.conference{
  width: 82%;
  max-width: 3000px;
}

.conferenceCard{
  width: 99%;
  margin: auto;
  /* border: 1px solid #00a5ff; */
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));

}

.fullSection{
  position: relative;
  top: -31px;
}

.annaulConference{
  position: relative;
  top: 11px;
}

.timeStamp{
  display: flex;
  justify-content: space-between;
}

/* .articleCard{
  position: relative;
  top: -10px;
} */

.eventFullSection{
  position: relative;
  top: -10px;
}

.eventDate{
  position: relative;
    top: -29px;
    /* width: 30%; */
    text-align: center;
    background-color: #104e29 !important;
    color: white;
    font-size: 13px;
}

.oneDayEvent{
  position: relative;
  top: -20px;
  width: 26% !important;
  text-align: center;
  background-color: #104e29 !important;
  color: white;
  font-size: 21px;
}

@media (max-width: 1376px) { 
  .oneDayEvent{
    width: 28%;
  }
}

@media (min-width: 992px) and (max-width: 1215px) { 
  .oneDayEvent{
    width: 37%;
  }
}
@media (min-width: 967px) and (max-width: 991px) { 
  .oneDayEvent{
    width: 26%;
  }
}

.bg-article{
  background-color: #104e29;
  color: white;
}

.eventHeading{
  width: 90%;
}

/* .headingConfig{
  color: #104e29;
  font-weight: 800;
} */

.viewAllEvents{
  color: #104e29;
  font-size: x-large;
  cursor: pointer;
}

.arrowConfig {
  border: 1px solid #104e29;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  margin-left: 6px;
}

.truncate-text {
  display: -webkit-box; /* Enable flex-like behavior for truncation */
  -webkit-box-orient: vertical; /* Set the box orientation to vertical */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  -webkit-line-clamp: 2; /* Limit to two lines */
  color: #104e29;
  position: relative;
    top: -24px;
}

.commonColor{
  color: #104e29;
}

@media (max-width: 768px) { 
  .ecard{
    width: 53% !important;
  }
 }

@media (max-width: 576px) { 
  .ecard{
    width: 70% !important;
  }
 }

 .custom-header {
  font-family: 'Signika', sans-serif;
  color: #104e29;
  font-weight: bold;
  margin-top: 20px;
  white-space: nowrap;
}

@media (max-width: 575.98px) { 
  .headingConfig{
    font-size: 17px !important;
  }
  .viewAllEvents{
    font-size: 17px !important;
  }
}

@media (max-width: 400.98px) { 
  .headingConfig{
    font-size: 15px !important;
  }
  .viewAllEvents{
    font-size: 15px !important;
  }
}