/* .banner_img {
    position: absolute;
    top: 0;
} */

.menus-colors {
  background-color: #1d924c !important;
  color: white;
  font-weight: bolder;
}

.menus-colors:hover {
  color: white !important;
  background-color: #a52a2a !important
}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

.logo {
  height: 68px;
}

.social_icons {
  display: grid;
  justify-content: center;
  align-content: center;
}

.rnd_fb {
  color: blue;
}

.cmn_icn {
  font-size: 1.8rem;
}

.top_nav {
  color: black;
}

.top_nav_btn {
  border-radius: 0px !important;
  background-color: #104e29 !important;
  color: #ffffff !important;
}
.header_gap{
  margin: 0px -127px 0px 73px;
  ;
}
/* .top_nav_btn_join {
  border-radius: 4px !important;
  background-color: brown !important;
  color: #ffffff !important;
  padding-left: 0px !important;
} */

.rnd_twitter {
  color: #1aa5d4;
}

.out_insta {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  border-radius: 5px;
  /* border-radius: 20px; */
  border-style: solid;
  border-width: 1px;
  /* background-color: #fc3831; */
  /* color: #ffffff; */
}

.out_utube {
  /* border-color: #6e6e6e; */
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  background-color: #fc3831;
  color: #ffffff;
}

/* .accountIcon {
  color: white;
} */

.contact_img {
  color: #1e75be;
}

@media only screen and (max-width: 915px) {
  .social_icons {
    display: none !important;
  }

  .logo_psn {
    display: none !important;
  }
}

.contact {
  position: relative;
  left: 6%;
  top: 13%;
}

.social_icons {
  display: grid;
  justify-content: end;
  position: relative;
  left: -38px;
}

.icon_container {
  position: relative;
  left: 6%;
  top: 13%;
}

/* .btn_container {
  position: relative;
  right: -27px;

} */

.without_btn {
  margin-right: 27px;
}

/* .banner_content {
  display: flex;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-weight: 800;
} */

.banner_content2 {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 800;
  /* font-size: clamp(2rem, 5vw, 5rem); */
}

.banner_content3 {
  display: flex;
  justify-content: center;
}

/* .banner_text {
    position: relative;
    background-image: url("../../../public/assets/images/banner/banner2.png") !important;
    height: 650px;
} */

.banner_text {
  position: relative;
  height: 650px;
  overflow: hidden;
  /* Hide overflowing content */
}

.banner_text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Make it cover the whole container */
  height: 100%;
  /* background-image: url("../../assets/images/banner/banner1.png"); */
  animation: slideImages 15s infinite;
  /* Adjust duration and iteration as needed */
  background-repeat: no-repeat;
}

@keyframes slideImages {
  0% {
    background-image: url("../../assets/images/banner/banner1.png");
  }

  33.33% {
    background-image: url("../../assets/images/banner/Group4.png");
  }

  66.66% {
    background-image: url("../../assets/images/banner/Group2.png");
  }

  100% {
    background-image: url("../../assets/images/banner/Group3.png");
  }
}


.btn1 {
  /* display: flex;
    justify-content: center; */
  background-color: #0072bb !important;
  color: white !important;
  border-radius: 150px !important;
  padding: 12px 26px 12px 26px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  border: none;
  filter: drop-shadow(2px 4px 6px black);
}

.btn_section {
  display: flex;
  justify-content: center;
  top: 50px;
  position: relative;
}

/* @media only screen and (max-width: 915px){
    .main{
        display: none !important;
    }
    .banner_content{
        color: black;
    }

} */
.total {
  position: relative;
  top: 200px;
}

.banner_content h5 {
  font-size: 30px;
  font-weight: 800;
  line-height: 183%;
  text-align: center;
}

@media only screen and (max-width: 868px) {
  .banner_content h2 {
    font-size: 25px;
  }

  .banner_content h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .banner_content h2 {
    font-size: 22px;
  }

  .banner_content h5 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 668px) {
  .banner_content h2 {

    font-size: 27px;
    line-height: 39px;
    text-align: center;
    padding: 10px;
  }

  .banner_content h5 {
    font-size: 18px;
    text-align: center;
    line-height: 39px;
  }
}

@media only screen and (max-width: 637px) {
  .banner_content h5 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 381px) {
  .banner_content h5 {
    font-size: 20px;
  }

  .banner_content h2 {
    font-size: 22px;
    text-align: center;
    line-height: 39px;
  }
}

@media only screen and (max-width: 317px) {
  .banner_content h5 {
    font-size: 16px;
  }

  .banner_content h2 {
    font-size: 19px;
    text-align: center;
    line-height: 39px;
  }
}

@media only screen and (max-width: 390px) {}

.side_logo {
  display: block;
  position: fixed;
  z-index: 2147483647;
  top: 40px;
  left: 27px;
  height: 55px;
}

@media only screen and (max-width: 1304px) {
  .side_logo {
    display: block;
    position: fixed;
    z-index: 2147483647;
    top: 40px;
    left: 13px;
    height: 55px;
  }
}

@media only screen and (max-width: 1304px) {
  .side_logo {
    display: block;
    position: fixed;
    z-index: 2147483647;
    top: 40px;
    left: 6px;
    height: 55px;
  }
}

@media only screen and (max-width: 1304px) {
  .side_logo {
    width: 90px;
  }
}

@media only screen and (max-width: 965px) {
  .side_logo {
    width: 80px;
  }
}

@media only screen and (max-width: 869px) {
  .side_logo {
    left: 2px;
  }
}

@media only screen and (max-width: 818px) {
  .side_logo {
    width: 75px;
  }
}

@media only screen and (max-width: 768px) {
  .side_logo {
    display: none !important;
  }
}

@media only screen and (max-width: 390px) {
  .btn_section>.btn1 {
    margin-top: -40px;
  }
}

.text1 {
  color: white;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 7px;
  margin-bottom: 20px;
  /* background: black; */
  position: relative;
  /*   shorthand animation property: name | duration | iteration count */
  animation: text 4s 1;
  animation-name: text;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

/* .text2 {
    font-size: 25px;
    color: #FFE997;
  } */

@keyframes text {
  0% {
    color: black;
    margin-bottom: -40px;
  }

  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }

  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }

  100% {
    margin-bottom: 20px;
  }
}


.typing-demo {
  animation: typing 4s steps(52), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  width: 72ch;
  letter-spacing: 9px;

}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}


.responsive-banner {
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  position: relative;
  min-height: 518px;
  max-height: 541px;
  overflow: hidden;
  /* background-image: url(../../assets/images/banner/eltai-B3-.jpg); */
  /* background-image: url(../../assets/images/banner/globe.jpg); */
  clip-path: path("M 0 0 L 0 80% Q 25% 85% 50% 80% T 100% 80% L 100% 0 Z");

  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: 50px;
  /* -webkit-animation: zoomin 10s ease-in infinite;
  animation: zoomin 10s ease-in infinite;
  transition: all .5s ease-in-out; */
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.responsive-banner {
  position: relative;
  overflow: hidden;
}

.responsive-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: inherit;
  transform-origin: center;
  animation: zoomIn 10s infinite alternate;
}

.container-envelope {
  position: relative;
  /* z-index: 1; */
  /* Ensure content stays above the background */
}




/* Zoom in Keyframes */
/* @-webkit-keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
}  */

/* @keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
} */
.responsive-banner.second {
  background-image: linear-gradient(to bottom right, #F4C28E, #E67163);
}

.responsive-banner.third {
  background-image: linear-gradient(to bottom right, #589FE5, #74DAC4);
}

.container-envelope {
  max-width: calc(100%);
  padding: 15px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 300px;
  max-height: 450px;
}

.col-xs-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@media (min-width: 650px) {
  .col-xs-12 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .container-envelope {
    max-width: calc(100% - 200px);
  }
}

.banners {
  clear: both;
  /* font-family: "Lora", serif; */
  font-optical-sizing: auto;
  text-transform: none;
  text-rendering: optimizeLegibility;
  font-weight: 700 !important;
  line-height: 2;
  word-wrap: break-word;
  margin: 1em 0 0.5em;
  margin: 0;
  padding: 0;
  /* color: white !important; */
  position: relative;
  word-wrap: break-word;
  font-size: 25px;
  /* text-transform: uppercase; */
  margin-bottom: 20px;
  margin-top: 100px;
  width: 65%;
  /* width: 100px; */

}

.bnr-slide {
  height: 100px;
  position: relative;
  animation: myfirst 15s infinite;
}

@keyframes myfirst {
  0% {
    left: 0px;
    top: 0px;
  }

  12.67% {
    left: 0px;
    top: 0px;
  }

  /* Wait for 5 seconds (1/3 of 15s) */
  50% {
    left: 600px;
    top: 0px;
  }

  /* Move to the right over 5 seconds */
  62.67% {
    left: 600px;
    top: 0px;
  }

  /* Wait for 5 seconds */
  100% {
    left: 0px;
    top: 0px;
  }

  /* Move back to the left over 5 seconds */
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a.more-link {
  background-color: rgb(165 42 42);
  display: inline-block;
  padding: 12px 18px;
  color: #fff !important;
  text-decoration: none;
  font: 500 15px 'Work Sans', Helvetica, sans-serif;
  line-height: 1.5;
  border: none !important;
  position: relative;
  border-radius: 30px;
  text-transform: uppercase;
  transition: .1s all ease-in-out;
  padding-left: 20px !important;
}

a.more-link:hover {
  background-color: rgba(255, 255, 255, 0.50);
}

.common-svg {
  fill: rgba(0, 0, 0, 0.1);
  position: absolute;
}

.common-svg {
  max-width: 50%;
}

.cirle-a {
  bottom: -80px;
  left: -80px;
}

.cirle-b {
  top: -30px;
  right: 70%;
}

.cirle-c {
  top: -330px;
  right: -300px;
}

.cirle-d {
  top: 195px;
  right: 145px;
}

.common-svg {
  top: 0;
  right: 0;
}

body {
  background-color: #eff2f7;
  margin-bottom: 50px;
  /* text-align: center; */
}

/* html,
button,
input,
select,
textarea {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
} */

h1 {
  text-align: center;
  margin: 30px 15px;
}

.link-container {
  text-align: center;
}

.link-container a.more-link {
  /* font-family: 'Source Sans Pro', Helvetica, sans-serif; */
  background-color: #90b0bf;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 1px;
}

.container-fluid {
  padding: 0px;
}

.btn_container.MuiBox-root.css-0 {
  margin-top: 6px;
}

.zoom {
  transition: transform 1s;
  /* Animation */
  /* width: 200px;
    height: 200px; */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.section-notch:before {
  -webkit-mask: radial-gradient(circle .8rem at 2px 50%, #0000 98%, #000) 0 0 / 100% 2.4rem;
}

.section-notch:before {
  content: "";
  position: absolute;
  /* background: url(../../assets/images/banner/section_notch_top.png); */
  background-repeat: repeat-x;
  display: block;
  top: 137px;
  width: 100%;
  height: 7px;
  z-index: 12;
}

.section-notch-down:before {
  content: "";
  position: absolute;
  /* background: url(../../assets/images/banner/section_notch_bottom.png); */
  background-repeat: repeat-x;
  display: block;
  top: 642px;
  width: 100%;
  height: 7px;
  z-index: 12;
}


/* Slide */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.sliding-in {
  animation: slideIn 1s forwards;
}

.sliding-out {
  animation: slideOut 1s forwards;
}


/* banner.css */

@keyframes slide-in-from-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-to-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in-from-right {
  animation: slide-in-from-right 1s forwards;
}

.slide-out-to-left {
  animation: slide-out-to-left 1s forwards;
}

.text-left {
  position: absolute;
  width: 100%;
  left: 10%;
  top: 20%;
  transform: translateX(0);
  text-align: center;
}

.text-right {
  position: absolute;
  right: -12%;
  top: 20%;
  width: 100%;
  transform: translateX(0);
  text-align: center;
}

@media (max-width: 646px) {
  .text-right {
    right: 1%;
    top: -15%;
  }

  .text-left {
    left: 1px;
    top: -15%;
  }
}

/* @media (min-width: 575px) and (max-width: 768px){
  .text-right {
    right: 1%;
    top: -15%;
  }
  .text-left {
    left: 19% !important;
    top: -15%;
  }
 }  */