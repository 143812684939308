
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');
.inter {
    font-family: "Inter", sans-serif;
  }
  @font-face {
    font-family: 'futura-bold';
    src: local('futura-bold'),
         url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
  }
.signika-font {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    /* font-style: normal; */
  }
  .SigEltai-paragraph-starting{
    text-align: justify;
    font-size: 18px;
  }
  .SigEltai-Green-start{
       color:#104E29;
       font-size: 18px !important;
  }
  .SigEltai-black-start{
    color:#000;
    font-size: 18px !important;
}