.galleryImg {
  border-radius: 0px;
  object-fit: fill;
}

.viewAllgallery {
  color: #104e29;
  font-size: x-large;
  cursor: pointer;
}

.arrowConfiggallery {
  border: 1px solid #104e29;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 6px;
  padding: 2px;
}

.galleryBatch {
  background-color: #ccc;
  padding: 5px 5px;
  display: flex;
  margin-right: '10px';
  color: red;
  text-transform: capitalize;
  text-align: center;
}

.galery-wrap-container {
  background-color: #d2dcc6;
}

.galleryImg:hover {
  border: 3px #333;
  border-style: dotted;
  padding: 7px;
  border-radius: 0px;
}

.galleryImg.active {
  border: 3px #333;
  border-style: dotted;
  padding: 7px;
  border-radius: 0px;
}

.gallerHover::after {
  pointer-events: none;
  /* This prevents the overlay from capturing clicks */
}

.galleryImg {
  z-index: 10;
  /* Ensure the image is above other elements */
}

.gallerHover {
  z-index: 5;
}

.selected-image {
  width: 100%;
  height: 380px;
  border-radius: 0px;
  object-fit: fill;
  position: relative;
}

.img-description {
  position: absolute;
  top: 60%;
  color: white;
  text-align: center;
  display: none;
}

.gallerHover {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  /* Ensure overlay stays within the container */
}

.gallerHover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 1%;
  width: 98%;
  margin-inline: auto;
  height: 50%;
  /* Adjust height of the overlay as needed */
  background: linear-gradient(to top, black, transparent);
  /* Gradient from black to white */
  opacity: 0;
  /* Start hidden */
  transition: opacity 0.3s;
  /* Smooth transition */
  border-radius: 0px;
}

.gallerHover:hover::after {
  opacity: 1;
  /* Show overlay on hover */
}

.gallerHover:hover .img-description {
  display: block;
  z-index: 2;
}

.custom-left-arrow {
  position: absolute;
  top: 50%;
  /* left: -50%; */
  transform: translateY(-50%);
}

.custom-right-arrow {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.carousel-container ul {
  transform: translate3d(-1358px, 0px, 0px) !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-container ul {
    transform: translate3d(36px, 0px, 0px) !important;
  }
}

@media (max-width: 767px) {
  .carousel-container ul {
    transform: translate3d(39px, 0px, 0px) !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.box-shadow-100 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.carousel-button-group {
  display: flex;
  justify-content: space-between;
  width: 90%;
  left: 5%;
  bottom: 8%;
  margin-inline: auto;
  position: absolute;
}

@media (max-width: 575.98px) {
  .carousel-button-group {
    bottom: 34%;
    /* width: 100%;
    left: 0%; */
  }
}

/* @media (min-width: 464px) and (max-width: 768px) {
  .carousel-button-group {
    width: 85%;
    left: 8%;
  }
} */
/* @media (min-width: 768px) and (max-width: 992px) {
  .carousel-button-group {
    width: 77%;
    left: 10%;
  }
} */
/* @media (min-width: 992px) and (max-width: 1200px) {
  .carousel-button-group {
    width: 98%;
    left: 1%;
  }
} */

@media (min-width: 320px) and (max-width: 450px) {
  .galleryImg {
    height: 136px !important;
    width: 211px !important;
  }

  /* .carousel-button-group {
    bottom: 23% !important;
} */
}

@media (min-width: 450px) and (max-width: 575px) {

  /* .carousel-button-group {
    bottom: 25%;
} */
  .galleryImg {
    height: 167px !important;
    width: 287px !important;
  }
}

/* @media (min-width: 1200px) and (max-width: 1350px) {
  .carousel-button-group {
    width: 100%;
    left: 0%;
  }
} */

@media (min-width: 575px) and (max-width: 767px) {
  .carousel-button-group {
    bottom: 28%;
  }
}

/* @media (min-width: 768px) and (max-width: 992px) {  
  .carousel-button-group{
    width: 98%;
    left: 1%;
  }
} */

.align_left {
  padding-right: 5rem !important;
}

@media (min-width:1200px) and (max-width:3000px) {
  .align_left {
    padding-right: 5rem !important;
  }
}

@media (min-width:992px) and (max-width:1150px) {
  .galleryImg {
    height: 150px !important;
    width: 275px !important;
  }
}

@media (min-width:567px) and (max-width:700px) {
  .galleryImg {
    height: 135px !important;
    width: 260px !important;
  }
}

@media (min-width:1600px) and (max-width:3000px) {
  .carousel-button-group {
    width: 4%;
    top: 62px;
    left: 92%;

  }
}

@media (min-width:1300px) and (max-width:1600px) {
  .carousel-button-group {
    width: 4%;
    top: 62px;
    left: 90%;

  }

  .galleryImg {
    height: 150px !important;
    width: 275px !important;
  }
}

@media (min-width:1200px) and (max-width:1300px) {
  .carousel-button-group {
    width: 4%;
    top: 62px;
    left: 88%;

  }
}

@media (min-width:767px) and (max-width:1200px) {
  .carousel-button-group {
    width: 6%;
    top: 66px;
    left: 87%;
  }
}

@media (min-width:600px) and (max-width:767px) {
  .carousel-button-group {
    width: 8%;
    top: 33px;
    left: 88%;
  }
}

@media (min-width:400px) and (max-width:600px) {
  .carousel-button-group {
    width: 10%;
    top: 31px;
    left: 83%;
  }
}

@media (min-width:180px) and (max-width:400px) {
  .carousel-button-group {
    width: 18%;
    top: 35px;
    left: 74%;
  }

  .view {
    padding-top: 12px;
  }

  .viewAllgallery {
    font-size: medium;
  }

  .custom-header {
    font-size: medium !important;
  }
}

.disable-prev {
  cursor: not-allowed;
  pointer-events: none;
}

.galleryImg {
  height: 150px;
  width: 325px;
}