@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');

.signika-font {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;


}

.bg-gradient {
  background: rgb(255, 255, 255) !important;
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 12%, rgba(124, 229, 250, 1) 100%) !important;
  background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 12%, rgba(124, 229, 250, 1) 100%) !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 12%, rgba(124, 229, 250, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#7ce5fa", GradientType=1) !important;
}


body {
  background-color: #eee
}

.card {
  border: none;
  /* border-radius: 10px */
}

.c-details span {
  font-weight: 300;
  font-size: 13px
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #eee;
  /* border-radius: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px
}

.badge span {
  background-color: #ccc;
  /* width: 60px; */
  height: 25px;
  padding: 3px;
  border-radius: 0px;
  display: flex;
  margin-right: '10px';
  color: red;
  text-transform: capitalize;
  justify-content: center;
  align-items: center
}

.progress {
  height: 10px;
  /* border-radius: 10px */
}

.progress div {
  background-color: red
}

.text1 {
  font-size: 14px;
  font-weight: 600
}

.text2 {
  color: #a5aec0
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none
}

.centered:hover {
  display: flex !important;
}

.onclick {
  cursor: pointer;
}

.member-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc !important;
  height: 175px;
  overflow-y: scroll;
  width: 92%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.member-ul::-webkit-scrollbar {
  display: none;
}

/* .customTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #611f1e;
}

.customTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #611f1e;
}

.customTextField .MuiInputLabel-root.Mui-focused {
  color: #611f1e;
}

 */
.customTextField {
  height: 30px;
  border-Radius: 20px;
  border: "none";
  border-radius: inherit;
  /* background-color: #c2dcd3 !important; */
}

.customTextField::placeholder {
  padding-left: 10px;
}

.chapter-border-font-color {
  color: #518b5b
}

.view-member-container {
  /* background-image: url('../../assets/images/interface/view-member-bgm.png'); */
  background-color: #FFECEC;
  width: 100%;
  height: auto;
}

.member-details-personal-container {
  background-color: #c2dcd3 !important;
}

.membershipDetails-card {
  background-image: url('../../assets/images/interface/member-details-bg.png');
  width: 100%;
  height: auto;
  background-repeat: repeat-y;
  display: flex;
  justify-content: center;

}

.Member-profile-Details-button {
  background-color: #518b5b !important;
  padding: 10px !important;
}


/* .customTextField{
  background-color:#c2dcd3 !important;
} */
.membership-title {
  width: 80%;
  position: relative;
  background-color: #5d8374;
  /* border-radius: 15px; */
  position: absolute;
  top: -18px;
  margin-left: 10%;

}

.membership-name-Date-bg-color {
  background-color: #ebdfde;
}

.memberShip-name {
  line-height: 0.5 !important;
  margin-bottom: 6px !important;
  font-size: 12px !important;
}

.sidebar {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky !important;
  height: 20vh;
  /* overflow-y: auto; */
}

.member-customScroll {
  max-height: 900px;
  overflow-y: auto;
}

.member-customScroll {
  max-height: 850px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbars in Webkit browsers */
.member-customScroll::-webkit-scrollbar {
  display: none;
}

.member-name-color {
  color: #fff !important
}

h5.member-name-color a {
  color: white !important;
  text-decoration: none !important;
  /* To remove underline */
}

.member-log-container {
  position: relative;
}

.member-logo {
  position: absolute;
  left: 50%;
  top: 40%;
}

.member-ship-plan-details {
  background-color: #d9d9d9;
  width: "100%";
  /* border-radius: 20px; */
}

.renew-membership {
  line-height: 0.5;
  color: rgb(97, 31, 30);
  text-decoration-line: underline;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {


  .member-logo {
    margin-top: 0%;
  }

  .member-details-personal {
    margin-left: -11%;
    font-size: 14px;
  }


}

/* @media screen and (min-width: 767px) and (max-width: 991px) {

.member-avatar{
  height: 11% !important;
  width: 90% !important;
}
  .member-logo {
    margin-top: -15%;
    margin-left: 5%;
  }
  .member-name-color{
    font-size: 15px;
  }

  .member-details-personal {
    margin-left: -11%;
    font-size: 9px;
  }

  .member-details-personal-container {
    background-color: #c2dcd3 !important;
  }
  .customTextField {
    height: 30px;
    width: 90%;
  border-Radius:6px;
  border:"none"
 }
 .memberShip-name {
  line-height: 0.5 !important;
  margin-bottom: 6px !important;
  font-size: 11px !important;
}
.renew-membership{
  line-height: 1;
  color: rgb(97, 31, 30);
  text-decoration-line: underline;
  background-color: #d9d9d9;
}
} */

/* @media screen and (min-width: 425px) and (max-width: 767px) {


  .member-logo {
    margin-top: -25%;
  }

  .member-details-personal {
    margin-left: -11%;
    font-size: 14px;
  }
  
    .member-details-personal-container {
      height: 125px;
  }
   
  } */


.border-shape {
  clip-path: polygon(98% 0, 87% 47%, 100% 100%, 0 100%, 15% 48%, 0% 0%);
}


/* .border-radius-member-container {
  border-radius: 20px;
} */

.news-author-color {
  color: #611f1e
}

.new-date {
  color: #546e6d;
  font-weight: 800;
}

.news-body {
  width: 100%;
  height: 70px;
  overflow-y: scroll;
}

.news-body::-webkit-scrollbar {
  display: none;
}

/* .news-badge-admin-container{
  position: relative;
} */
.news-badge-admin {
  background-color: #eee;
  color: #000;
  height: 20px;
  /* position: absolute; */
}

.news-image-member-feed {
  object-fit: fill;
  /* border-radius: 10px; */
}

.news-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/*NEWS-CHAPTER*/
.portal-chapter-admin .news-bg-artical-details-bg-color {
  border: 2px solid #104E29 !important;
  color: #104E29;
  background-color: #ffff;
  height: 165px !important;
}

.portal-chapter-admin .news-bg-color {
  border: 3px solid #104E29 !important;
  color: #104E29;
  background-color: #ffff;
}

/*NEWS ADMIN*/
.portal-super-admin .news-bg-artical-details-bg-color {
  background-color: #ffff;
  border: 2px solid #ED6B1B !important;
  height: 165px !important;

}

.portal-admin .news-bg-artical-details-bg-color {
  background-color: #ffff;
  border: 2px solid #EDAB1B !important;
  height: 165px !important;

}

.portal-super-admin .news-bg-color {
  border: 3px solid#ED6B1B !important;
  background-color: #ffff;
}

.portal-admin .news-bg-color {
  border: 3px solid#EDAB1B !important;
  background-color: #ffff;
}

.portal-super-admin-gallery .gallery-bg-color {
  border: 3px solid#ED6B1B !important;
  background-color: #ffff !important;
}

.portal-admin-gallery .gallery-bg-color {
  border: 3px solid#EDAB1B !important;
  background-color: #ffff !important;
}

.portal-sig-admin-gallery .gallery-bg-color {
  border: 3px solid #7D2122 !important;
  background-color: #ffff !important;
}

.portal-chapter-admin-gallery .gallery-bg-color {
  border: 3px solid #104E29 !important;
  background-color: #ffff !important;
}

/*NEWS siG*/
.portal-sig-admin .news-bg-artical-details-bg-color {
  border: 2px solid #7D2122 !important;
  background-color: #ffff;
  height: 165px !important;
}

.portal-sig-admin .news-bg-color {
  border: 3px solid #7D2122 !important;
  background-color: #fff;
}

.admin-font-event-color {
  color: black
}

.portal-super-admin-event .event-member-feed-learn-more-button {
  background-color: #FFFF !important;
  color: #000 !important
}

.portal-super-admin-event .event-bg-color {
  border: 3px solid #ED6B1B !important;
  background-color: #ffff;
}

.portal-admin-event .event-bg-color {
  border: 3px solid #EDAB1B !important;
  background-color: #ffff;
}

/* .portal-super-admin-event .event-member-details {
 
} */

.portal-chapter-admin-event .event-bg-color {
  /* background-color: #50a1a5; */
  border: 3px solid #104E29 !important;
  color: #104E29;
  background-color: #ffff;
}

.portal-chapter-admin-event .event-member-details {
  color: #000
}

.portal-chapter-admin-event .event-member-feed-learn-more-button {
  background-color: #cae4e5 !important;
  color: #000 !important
}

.portal-sig-admin-event .event-bg-color {
  /* background-color: #fabb9b; */
  border: 3px solid #7D2122 !important;
  background-color: #fff;
}

.portal-dig-admin-event .event-member-details {
  color: #000
}

.portal-sig-admin-event .event-member-feed-learn-more-button {
  background-color: #f6e9e4 !important;
  color: #000 !important
}

.portal-super-admin-forum .forum-bg-color {
  /* background-color: #FFF; */
  border: 3px solid#ED6B1B !important;
  background-color: #ffff;
}

.portal-admin-forum .forum-bg-color {
  /* background-color: #FFF; */
  border: 3px solid#EDAB1B !important;
  background-color: #ffff;
}

.portal-super-admin .gallery-bg-color {
  border: 3px solid#ED6B1B !important;
  background-color: white;
}

.portal-super-admin-announcement .announcement-bg-color {
  border: 3px solid#ED6B1B !important;
  background-color: white;
}

.portal-admin-announcement .announcement-bg-color {
  border: 3px solid#EDAB1B !important;
  background-color: white;
}

.portal-chapter-admin-announcement .announcement-bg-color {
  border: 3px solid #104E29 !important;
  color: #104E29;
  background-color: white;
}

.portal-sig-admin-announcement .announcement-bg-color {
  border: 3px solid #7D2122 !important;
  color: #7D2122;
  background-color: white;
}

.portal-super-admin-forum .forum-bg-artical-details-bg-color {
  background-color: #fff;
  border: 2px solid #c9cde4
}

.portal-chapter-admin-forum .forum-bg-color {
  border: 3px solid #104E29 !important;
  color: #104E29;
  background-color: #ffff;
}

.portal-chapter-admin-forum .forum-bg-artical-details-bg-color {
  background-color: #ffff;
}

.portal-sig-admin-forum .forum-bg-color {
  background-color: #fff;
  border: 3px solid #7D2122 !important
}

.portal-sig-admin-forum .forum-bg-artical-details-bg-color {
  background-color: #ffff;
}



@media screen and (min-width: 0px) and (max-width: 429px) {
  .mobile-wall-post {
    padding-top: 14px;
  }
}

.member-details-count {
  margin-left: 5px;
}

.Chapter-font-color {
  color: #104E29;
}

.sig-font-color {
  color: #7D2122;
}

.Gallery-button {
  clip-path: polygon(71% 1%, 100% 50%, 75% 100%, 0% 100%, 0 48%, 0% 0%);
  padding: 0px;
  background-color: #104E29;
  height: 40px
}

.membership-profile {
  background-image: url('../../assets/images/interface/member-details-bg.png');
  width: 100%;
  height: 350px;
  /* Set a fixed height */
  background-size: cover;
  /* Scale the image */
  background-position: center;
  /* Center the image */
}

#modulesDropdown {
  width: 88%;
  border-radius: inherit;
}

.sig-feed-font-color {
  color: #7D2122
}

.cursor-pointer {
  cursor: pointer;
}

.list-group-item {
  position: relative;
  z-index: 10;
  /* Ensures it appears on top */
}

.card.position-sticky {
  z-index: 5;
  /* Lower z-index than list items */
}

@media screen and (min-width:992px) and (max-width: 1300px) {
  .membership-title #membership-details-title {
    font-size: 12px;
  }
}

.no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  /* Full viewport height */
  text-align: center;
}

@media screen and (min-width:200px) and (max-width: 575px) {
  .no-data-container {
    height: 25vh
  }
}

/* .super-Admin-avatar{
  background-color: #ED6B1B !important;
 }
 .Chapter-font-color{
  background-color: #104E29 !important;
 }
 .SIG-Admin-avatar{
  background-color: #7D2122 !important;
 } */
.upcoming-event:hover {
  color: #fff
}

.portal-member .border-radius-member-container {
  border: 3px solid #1d924c !important;
  background-color: #ffff;
}

.non-receipt-font-color {
  color: #1d924c
}

.membership-mobileResponsive {
  position: absolute;
  z-index: 1;
  top: 225px;
}

.member-details--mobileResponsive {
  position: absolute;
  z-index: 1;
}

/* .galleryPost-image{ */
/* border-radius: 10px !important; */
/* } */
.fade {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  /* Adjust duration as needed */
}

.fade-in {
  opacity: 1;
}

.animate {
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tranprant-loading {
  background-color: transparent;
  padding: 50px;
}

.border-radius-membership-wallpage-button {
  border-radius: inherit !important;
}

@media (min-width: 1536px) {
  .css-19r6kue-MuiContainer-root {
    max-width: 2000px !important;
  }
}

.announcement-text-wrap {
  text-align: justify;
  word-Wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap,
}