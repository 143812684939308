.membership-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  /* background: linear-gradient(135deg, #6e85b7, #b4c8e8); */
  font-family: 'Arial', sans-serif;
  color:black;
  padding: 120px 0px;
  text-align: center;
  background-image: url(../../assets/images/ourExecutiveCommittee/BG.png);
  width: 100%;
  background-size: cover;
}

.content-container {
  max-width: 1048px;
}

.main-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #104e29;
  font-weight: 700;
}

.highlight {
  color: #104e29;
  font-weight: 600;
}

.sub-text {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  text-align: justify;
}

.cta-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #0056b3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #004494;
}
