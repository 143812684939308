/* button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.Mui-selected.css-g7oznu-MuiButtonBase-root-MuiTab-root {
    margin: 10px !important;
    padding: 0px !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.Mui-selected.css-1qg2y75-MuiButtonBase-root-MuiTab-root {
    margin: 10px !important;
    padding: 0px !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.css-po3vbb-MuiButtonBase-root-MuiTab-root {
    margin: 10px !important;
    padding: 0px !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.css-po3vbb-MuiButtonBase-root-MuiTab-root {
    margin: 10px !important;
    padding: 0px !important;
} */

/* .MuiTabs-root.css-udzhn6-MuiTabs-root {
    border-radius: 15px;
} */

.img-over {
    position: relative;
    width: 100%;
  }
  
  .text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: #fff;
    padding: 9px;
    text-align: center;
    top: 1px !important;
    left: 2px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(182, 123, 99, 0.5); */
}
.prof{
    margin-top: 10px;
}
.bg-img{
    background-image: url(../../assets/images/interface/page-bg.png);
    background-size:'cover';
    background-position:'center';
    background-repeat:'no-repeat';
}
.MuiTabs-root.css-udzhn6-MuiTabs-root {
    border-radius: 10px;
}
img#profileImage {
    width: 100%;
    height: auto;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 16.5px 25px !important;
}

@media screen and (min-width: 1200px) and (max-width: 1430px) {
    .text-overlay{
     margin-top: -17%;
    }
  
   }
   @media screen and (min-width:768px) and (max-width:992px) {
  
  .member-details-name{
    margin-top: -60%;
  }
  .Member-profile-picture{
    
  }
   }
