.card-journal {
    /* border-top: 1px solid light-grey;
    border-left:1px solid light-grey; */
    border:1px solid grey;
    padding: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 8px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  