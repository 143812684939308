.banner-container {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(2px 4px 6px black);
  }
  
  .banner-slide {
    position: absolute;
    top: 0;
    left: 100%; /* Initially out of view */
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .banner-slide.active {
    left: 0;
    opacity: 1;
  }
  
  .banner-text {
    font-size: 20px;
    text-align: justify;
    width: 35% !important;
    bottom: 20px !important;
  }
  
  .dynamicButton {
    border-radius: 0px !important;
    background-color: #104e29 !important;
    color: #ffffff !important;
    border: none;
  }

  @media (max-width: 767.98px) { 
    .banner-text{
        width: 85% !important;
        /* top: 5%; */
        bottom: 155px !important
    }
   }