.card-download-pdf{
    background-color: white !important;
    font-family: 'Alata';
    border-radius: 12px;
    border:2px solid #104e29 !important;
}
.pdf-generator-nember-eltai-font-size {
    font-family:  'Alata'; /* Change this to your desired font */
}
.card-download-pdf-green-color-font{
    color:#104e29
}
.card-download-pdf-address{
    font-size: 19px;
}
.card-download-pdf-phone{
    border:2px solid #104e29;
    padding: 2px;
    background-color: #104e29;
    border-radius: 50%;
}
.card-download-pdf-white{
    color:#fff
}
.icon-social-media-pdf-card{
    margin-left: 5px;
}
.card-download-pdf-brown-color-font{
    color:#62201f
}
.card-download-black-color-font{
    color:#000
}
.pdf-export-font-size{
 font-size: 12px !important;
 color:#104e29 !important
}
/* Print-specific styles */
.email-pdf-font-size{
    font-size: 14px !important;
    color:black !important
}
