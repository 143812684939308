/* .card .office-bearers-card{
    
} */
.banner_text {
    position: relative;
    height: 650px;
}

.p_bearers {
    text-align: center;
    font-size: 16px;
    margin-top: -21px;
    margin-left: -25px;
    margin-right: -25px;
    color: black;
    background-color: #DEE6CD;
}

.positiontxt {
    color: black !important
}

.card_bearers {
    /* border: 1px solid; */
    border-radius: 0px 0px 15px 15px;
    /* background-color: #3d4670; */
    height: 9rem;
    padding: 25px;
    width: 15rem;
}

.office_card {
    width: 81%;
    object-fit: fill;
    height: 15rem;
}

.cardoverlay {
    width: 308px !important;
    top: -82px !important;
    left: -4%;
}

.card_background {
    margin-left: 0px;
}

.card_barrer {
    height: 11rem;
    padding: 25px;
    width: 15rem;
}

.animate-borders-bearers {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: #611f1e;
    /* margin-left: 483px; */
}

.animate-borders-bearers:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 10px solid #fa6c6c;
    border-right: 10px solid #fa6c6c;
    -webkit-animation: animborder 2s linear infinite;
    animation: animborder 2s linear infinite;
}

.animate-border-bearers {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: #611f1e;
    /* margin-left: 483px; */
}

.animate-border-bearers:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 10px solid #fa6c6c;
    border-right: 10px solid #fa6c6c;
    -webkit-animation: animborder 2s linear infinite;
    animation: animborder 2s linear infinite;
}

.office_bearers {
    background: rgb(245, 245, 245);
    background: -moz-linear-gradient(184deg, rgba(245, 245, 245, 1) 21%, rgba(206, 237, 252, 1) 100%);
    background: -webkit-linear-gradient(184deg, rgba(245, 245, 245, 1) 21%, rgba(206, 237, 252, 1) 100%);
    background: linear-gradient(184deg, rgba(245, 245, 245, 1) 21%, rgba(206, 237, 252, 1) 100%);
}

@-webkit-keyframes animborder {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(113px);
        transform: translateX(113px);
    }
}

@keyframes animborder {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(113px);
        transform: translateX(113px);
    }
}

.animate-border-bearers.border-white:after {
    border-color: #fff;
}

.animate-border-bearers.border-yellow:after {
    border-color: #F5B02E;
}

.animate-border-bearers.border-orange:after {
    border-right-color: #0c3d5d;
    border-left-color: #0c3d5d;
}

.animate-border-bearers.border-ash:after {
    border-right-color: #EEF0EF;
    border-left-color: #EEF0EF;
}

.animate-border-bearers.border-offwhite:after {
    border-right-color: #F7F9F8;
    border-left-color: #F7F9F8;
}

/* Animated heading border */
@keyframes primary-short {
    0% {
        width: 15%;
    }

    50% {
        width: 90%;
    }

    100% {
        width: 10%;
    }
}

@keyframes primary-long {
    0% {
        width: 80%;
    }

    50% {
        width: 0%;
    }

    100% {
        width: 80%;
    }
}

.heading_h3_bearers {
    color: black;
    font-weight: 700;
    /* text-align: center; */
}

@media screen and (min-width:850px) and (max-width:920px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 266px;
    }

}

@media screen and(max-width:300px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 28px;
    }
}

@media screen and (min-width:400px)and(max-width:520px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 40px;
    }
}

@media screen and (min-width:800px) and (max-width:740px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 208px
    }
}

@media screen and(max-width:300px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 28px;
    }
}

@media screen and (min-width:1010px) and (max-width:1240px) {
    .cardoverlay {
        width: 246px !important;
        top: -82px !important;
        left: -4%;
    }

    .card_barrer {
        height: 11rem;
        padding: 25px;
        width: 12rem;
    }

    .card_bearers {
        border-radius: 0px 0px 15px 15px;
        height: 9rem;
        padding: 25px;
        width: 12rem;
    }

    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 318px;
    }
}

@media screen and (min-width:1240px) and (max-width:1280px) {
    .animate-borders-bearers {
        position: relative;
        display: block;
        width: 115px;
        height: 3px;
        background: #611f1e;
        margin-left: 448px;
    }
}

