.table-responsive {
    border: 1px solid #ccc;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: center;
}
/* Default for large screens */
@media (min-width: 1200px) {
    .menus-color {
        font-size: 16px;
    }
    .table {
        font-size: 14px;
    }
}

/* Medium screens (tablets and smaller desktops) */
@media (max-width: 1199px) and (min-width: 768px) {
    .menus-color {
        font-size: 14px;
    }
    .table {
        font-size: 12px;
    }
}

/* Small screens (phones) */
@media (max-width: 767px) {
    .menus-color {
        font-size: 12px;
        width: 100%;
        padding: 10px;
    }

    .table-responsive {
        overflow-x: auto;
    }

    .table th, .table td {
        font-size: 10px; /* Smaller font size for smaller screens */
        white-space: normal; /* Allow text to wrap in small screens */
    }

    .table th {
        padding: 8px;
    }
    
    .table td {
        padding: 6px;
    }
}
