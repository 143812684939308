.notice-board {
    background: peachpuff;
    width: 377px;
    height: 300px;
    /* border: 2px solid #b08d57; */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
    overflow: hidden;
  }

  
  .pinned-card {
    background: #ffffff;
    width: 250px;
    padding: 15px;
    position: relative;
    top: 42px;
    left: 59px;
    transform: rotate(-4deg); /* Initial tilt */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3), inset 0 -3px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    transition: transform 0.3s ease; /* Smooth transition */
    background: seashell;
  }
  
  .realistic-pin {
    width: 16px;
    height: 16px;
    background: radial-gradient(circle, #d32f2f 0%, #b71c1c 70%);
    border: 2px solid #880e4f;
    border-radius: 50%;
    position: absolute;
    top: -12px;
    left: calc(50% - 8px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
  
  .realistic-pin::after {
    content: "";
    width: 4px;
    height: 20px;
    background: linear-gradient(to bottom, #b71c1c, #880e4f);
    position: absolute;
    top: 14px;
    left: 6px;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Hover Effect */
  .pinned-card:hover {
    animation: tilt-once 0.6s ease-in-out;
  }
  
  @keyframes tilt-once {
    0% {
      transform: rotate(-4deg);
    }
    25% {
      transform: rotate(-6deg);
    }
    50% {
      transform: rotate(-2deg);
    }
    75% {
      transform: rotate(-6deg);
    }
    100% {
      transform: rotate(-4deg);
  }
}
  
  .pinned-card h3 {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .pinned-card p {
    font-size: 14px;
    color: #666;
    text-align: justify;
  }
  
  @media (max-width: 410px) { 
    .notice-board{
        width: 312px;
    }
   }

   .page{
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
   }

   /* .page-right{
    -webkit-mask: radial-gradient(circle 0.8rem at calc(100% - 2px) 50%, #0000 98%, #000) 0 0 / 100% 2.4rem;
   }

   .page-left{
    -webkit-mask: radial-gradient(circle .8rem at 2px 50%, #0000 98%, #000) 0 0 / 100% 2.4rem;
   } */

   .page-number{
    position: relative;
    top: 25%;
    left: 50%;
   }