
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');
.inter {
    font-family: "Inter", sans-serif;
  }
  @font-face {
    font-family: 'futura-bold';
    src: local('futura-bold'),
         url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
  }
.signika-font {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    /* font-style: normal; */
  }
  .teacher-member-card{
    background-color: #fff;
    border:2px solid #307D50 !important ;
    position: relative;
    border-radius: inherit;
  }
  .techer-member-image-editIcon{
    color: white;
    background-color: darkgreen;
    width: 23px;
    height: 23px;
    padding: 7px;
    position: relative;
    z-index: 1;
    justify-content: center;
    display: flex;
    top: 60%;
    border-radius: 50%;
    left:75%;
    font-weight: 700;
  }
  .edit-button-profile-membership{
    color: green !important;
    border: 1px solid green !important;
    border-radius: inherit !important;
}
.institute-payment{
  margin-left:20px
}
.link-pdf-member{
color:#006400 !important
}
.card-amount-membership{
  background-color: #d2dcc6;
}
.corparate-font-color{
  color:#a2a2a2
}
.card-border-member-sharps{
  border-radius: inherit;
}