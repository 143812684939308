/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap') */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Signika:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #ffff;
  /* font-family: "Roboto", sans-serif; */
}

:root {
  /* --primary-color: #0072bc;
  --hover-primary-color: #0080bc; */
  --secondary-color: #601e1d;
  --hover-secondary-color: #701e1d;
  --neutral-color: #ebebeb;
  --all-menus-color: #611f1e;
  --menus-green-color: #104e29;
  --hover-green-color: #104035;
}

.menus-color {
  background-color: var(--all-menus-color) !important;
  color: white !important;
  padding: 6px 16px !important;
}

button.menus-color {
  border-radius: 0;
}

.menus-green-btn {
  background-color: var(--menus-green-color) !important;
  color: white;
}

.menus-green-btn:hover {
  background-color: var(--menus-green-color) !important;
  color: white;
}

.menus-green-btn:disabled {
  background-color: #104e2975 !important;
  color: #fafafa !important;
  cursor: not-allowed !important;
}

.menus-green-btn-outline {
  background-color: white;
  color: var(--menus-green-color) !important;
  border: 2px solid var(--menus-green-color) !important;
}

.menus-green-btn-outline:hover {
  background-color: var(--menus-green-color) !important;
  color: white !important;
  border: 2px solid var(--menus-green-color) !important;
}

.menus-green-btn-outline:disabled {
  background-color: var(--menus-green-color) !important;
  color: white !important;
  opacity: 0.6;
  border: 2px solid var(--menus-green-color) !important;
}

.menus-green-color {
  color: var(--menus-green-color);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  /* font-family:'Roboto',sans-serif; */
}

.icon:hover {
  color: var(--secondary-color);
}

.page-containter {
  background-image: url(assets/images/Architects/BG.png);
  width: 100%;
  height: auto;
}

.bg-green-new {
  background-color: var(--menus-green-color) !important;
}

.bg-green-accent-new {
  background-color: var(--hover-green-color) !important;
}

.modal-backdrop {
  z-index: -1;
}

img {
  object-fit: scale-down;
}

.disablebtn {
  background: transparent;
  color: #fafafa;
  pointer-events: none;
  cursor: none;
}

.disabled {
  background: #fafafa;
  color: #ccc;
  pointer-events: none;
  cursor: none;
}

.icon-join-color {
  color: #14e140 !important;
}

.icon-exit-color {
  color: #e11414 !important;
}

.icon-vieweye-color {
  color: #ff8000;
}

.requested {
  color: #cabe00;
  font-weight: 600;
}

.joined {
  color: green;
  font-weight: 600;
}

.notJoined {
  color: red;
  font-weight: 600;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

.signika {
  font-family: "Signika", sans-serif;
}

.error-message {
  font-family: "Signika", sans-serif;
  font-size: 0.875rem;
  color: red;
}

.stsearch-box input {
  border: none;
}

.stsearch-box {
  padding: 10px;
}

a:link {
  text-decoration: none;
}

.text-secondary-new {
  color: var(--secondary-color) !important;
}

.justified {
  text-align: justify;
}

.aspect4 {
  aspect-ratio: 4/4;
}

.alata-light {
  font-family: "Alata", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.invoice {
  font-family: "Alata", sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  color: #501919;
}

.alata-regular {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
}

.alata-medium {
  font-family: "Alata", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.alata-semibold {
  font-family: "Alata", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.alata-bold {
  font-family: "Alata", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.border-green-color {
  border: 2px solid #104E29;
}

.green-box {
  background-color: #104E29;
  width: 80px;
  height: 120px;
}

.red-box {
  background-color: #601e1d;
  width: 80px;
  height: 120px;
}

.orange-box {
  background-color: #e11414;
  width: 80px;
  height: 120px;
}

.text-danger-50 {
  color: #ff000075
}

.deleteyes{
  background-color: green !important
}
.deleteno{
  border: 1px solid rgb(220, 53, 69) !important ;
  color: rgb(220, 53, 69) !important
}

@media (min-width:992px) {
  .overallPadding {
    padding: 40px 75px !important
  }
}

.news-card-122 {
  height: 513px !important;
  margin-bottom: '70px';
}

.overlay-style {
  position: absolute;
  top: 0;
  width: 91%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.overlay-style-1 {
  position: absolute;
  top: 0;
  width: 91%;
  height: 110%;
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width:992px) {
  .news-card-122 {
    height: 100% !important;
    margin-bottom: '0';
  }

  .overlay-style {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .overlay-style-1 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

}

@media (min-width:1024px) {
  .bottom-lg-25 {
    bottom: -5.5% !important;
  }
}