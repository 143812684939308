.card-contact-us-eltai{
  background: transparent;
  background-color: rgba(75, 75, 71, 0.203);
}

.location-address {
  font-size: 19px;
  text-align: center;
}

.location-icon-contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-icon-contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-number {
  font-size: 21px;
  text-align: center;
  justify-content: left;
}

.email-font {
  font-size: 21px;
  text-align: center;
  font-weight: 600;
}

.followus-font {
  font-size: 16px;
  font-weight: 200;
  justify-content: center;
  align-items: center;
}

.call-icon-contact-us-image {
  height: 80px;
  width: 60px;
}

.location-icon-image {
  height: 80px;
  width: 60px;
}

.mail-icon-image {
  height: 90px;
  width: 70px;
}

.icon-image {
  width: 40px;
  /* Adjust the size as needed */
  height: 40px;
  /* Maintain aspect ratio */
  margin-left: 15px;
  margin-right: 15px;
}

.followus-font {
  display: flex;
  align-items: center;
}

.about-us-para {
  font-size: 17px;
  font-weight: 600px !important;
  text-align: center;
  justify-content: center;
}

.eltai_logo {
  text-align: center !important;
  padding: 30px, 25px, 30px, 25px;
}

.background {
  background-image: url('../../assets/images/chapter/Background-page.png');
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
}