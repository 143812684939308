b { /* used for event dates/times */
    margin-right: 3px;
  }
  
  .demo-app {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }
    
  .demo-app-main {
    flex-grow: 1;
    padding: 3em;
  }
  
  .fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
  }
  .fc .fc-col-header-cell-cushion{
    text-decoration:none;
    color:#104E29 !important;
  }
  .fc .fc-daygrid-day-number{
    text-decoration:none;
    color:#a52a2a !important;
  }
  .fc .fc-button-primary{
    background-color:#104E29;
    text-transform:capitalize;
  }
  .fc .fc-button-primary:hover{
    background-color:#398156;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
    background-color:#398156;
  }
 
  .fc-toolbar-title{
    font-family:"Signika", sans-serif;
  }
  .fc .fc-daygrid-more-link{
    padding:auto !important;
    text-decoration:none;
    color:#104e29;
  }
  .fc-daygrid-day-bottom{
    margin-top:-15px !important;
  }
  @media screen and (max-width:590px){
    .fc .fc-toolbar{
        flex-direction:column-reverse
    }
  }