.memberTable{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 8%);
}
.event-admin-table{
    width:100%;
}
.css-10dfkli-MuiTableCell-root{
    text-align: center;
}

p.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
    color: black;
    margin-top: 15px;
}