.viewcardBg{
    /* padding-left: 44px; */
   padding: 0px 3px !important;
   border: none;
   border-radius: 10px;
   background:none
}
.viewcardBgover {
    /* padding-right: 193px; */
    padding: 0 0px !important;
}

.viewcard-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius);
}
.viewcardBgtxt {
    padding-right: 144px;
    font-weight: 700;
    /* align-Items: center !important; */
    text-align: start !important;
}
.viewcardBgptxt {
    background-color: #00000035;
    text-align: justify;
    color: white;
    height: 200px;
    overflow: hidden;
    padding:15px;
    font-weight: 600 !important;
}
.viewsideCard {
    object-fit: none;
    width: 396px;
    height: 281px;
    border-radius: 16px;
}
.aproveorreject{
    text-align: center;
}