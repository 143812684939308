.banner_heading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.add-btn {
    padding: 5px 28px;
    background-color: green;
    border-radius: 7px;
    color: white;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}

.remove-btn {
    padding: 5px 28px;
    background-color: red;
    border-radius: 7px;
    color: white;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}

input#\:rv\: {
    cursor: pointer;
}

.time-picker {
    width: 100% !important;
}

.descrption {
    text-align: justify;
}