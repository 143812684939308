
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');
.inter {
    font-family: "Inter", sans-serif;
  }
  @font-face {
    font-family: 'futura-bold';
    src: local('futura-bold'),
         url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
  }
.signika-font {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    /* font-style: normal; */
  }
  .our-history-intro{
    font-size: 23.5px;
    text-align: justify;
  }
  .history-title1{
    color:#307D50;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title2{
    color:#3F307D;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title3{
    color:#7C307D;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title4{
    color:#7D3034;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title5{
    color:#429FFD;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title6{
    color:#755DA3;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title7{
    color:#EC4B5D;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title8{
    color:#26979F;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title9{
    color:#F0077B;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title10{
    color:#5555FF;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title11{
    color:#FBC926;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title12{
    color:#388E3C;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-title13{
    color:#106BB4;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-badge{
    width:70px;
    height:80px;
    font-weight: bold;
    font-family: 'futura-bold';
  }
  .history-content{
    text-align: justify;
  }

  .ourhistory_card{
    padding: 1pc;
    border: 2px solid;
  }
  .ourhistory_content{
    font-size: 18px;
    text-align: justify;
  }