@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');

.signika-font-indiareads {
  font-family: "Signika", sans-serif;
  font-size: 15px;
  text-align: justify;
  font-optical-sizing: auto;
  font-weight: 350;
}

.india-reads-firstpara {
  font-size: 18px;
}

.india-reads-header-font {
  font-family: "Signika", sans-serif;
  font-weight: 600;
  color: #233e2d;
  font-size: 25px;
}

.india-reads-header-font-small {
  font-family: "Signika", sans-serif;
  font-weight: 400;
  font-size: medium;
  color: #233e2d;
}

.india-reads-approach-bold {
  font-weight: 550;
}

India-reads-Our-Approach-bullets li::marker {
  color: #233e2d;
  font-size: 1.5em;
  /* Increase the bullet size */
  font-weight: 150;
}

.background {
  background-image: url('../../assets/images/chapter/Background-page.png');
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
}

.india-Read-content-size {
  font-size: 18px;
}