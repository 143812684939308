.memberCard {
    flex: 1 1 calc(33.333% - 1rem);
    max-width: calc(19.333% - 1rem);
    min-width: 250px;
}
 
.title-with-bg {
    background-image: url('../../assets/images/Membership/Mask\ group\ 2.png');
    /* background-size: cover; */
    background-position: center;
    background-repeat: no-repeat;
    padding: 10px 0px;
    background-color: #fafafa;
    color: #000000;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
}
 
.formLink {
    background-color: #134b2b;
}
 
.caption {
    text-align: justify;
}
 
 
@media (min-width: 1140px) and (max-width: 1199.98px) {
    .last-item-class {
        position: relative !important;
        right: 375px !important;
    }
}
 
@media (max-width: 1140px) {
    .last-item-class {
        position: relative;
        right: 358px;
    }
}
 
@media (max-width: 1090px) {
    .last-item-class {
        position: relative;
        right: 331px;
    }
}
 
@media (max-width: 1040px) {
    .last-item-class {
        position: relative;
        right: 313px;
    }
}
 
@media (max-width: 955px) {
    .last-item-class {
        position: relative;
        right: 285px;
    }
}
 
@media (max-width: 900px) {
    .last-item-class {
        position: relative;
        right: 268px;
    }
}
 
@media (max-width: 878px) {
    .last-item-class {
        position: relative;
        right: 0px;
    }
 
    .memberCard {
        flex: 1 1 calc(33.333% - 1rem);
        max-width: calc(45.333% - 1rem);
        min-width: 250px;
    }
 
}
 
.memberFlex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
 
@media (max-width: 548px) {
    .memberFlex{
        display: flex;
        justify-content: center;
    }
}
 