@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* .starting-chapter-container{
  background-image: url('../../../../public/assets/images/chapter/chapter\ 1.png');
  max-width: 100%;
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
} */
.font-family1{
  font-family: "poppins" !important;
}
.pp{
  font-family: "poppins " !important;
}
.Guidlines-Chapter-heading{
  font-family: "poppins " !important;
  font-weight: 900;
}
.staring-Chapter-title1{
  font-family: "poppins " !important;
}
.card-Chapter-heading1{
  font-family: "poppins " !important;
  font-weight: 900;
 
}
.card-container{
  background-image: url('../../../../public/assets/images/chapter/cardbg.png');
  width: 100%;
  background-position: center;
}
.chapter-important-news{
  padding: 10px;
  color: #611f1e;
  font-weight: 600;
  font-family:"poppins " !important;
}
.card-container-start-chapter{
  border-radius: 40px;
  border:2px solid #878787;
  box-shadow: 5px 5px #ddd;
}
.chapter-span-footer{
  color: #611f1e;
}
.chapter-bottom{
  width:90%;
  margin: 0 auto;
}