.gallery-ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc !important;
    height: 175px;
    overflow-y: scroll;
    width: 92%;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.gallery-ul::-webkit-scrollbar {
    display: none;
  }
  
.gallery-li{
    padding: 8px;
    cursor: pointer;
  
}
.gallery-li:hover{
    background-color: #cccc !important;
}