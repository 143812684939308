@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');

.inter {
  font-family: "Inter", sans-serif;
}

.signika-font {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
}

.signika-fontOEC {
  font-weight: 800;
  font-family: "Inter"
}

.eltaimember-image-important-person {
  width: 100%;
  height: 185px;
}

.eltaimember-card-important-person {
  background-color: #d9d9d9;
  position: relative;
  margin-top: 90px;
  margin-bottom: 70px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.executive-card-important-person {
  background-color: #d9d9d9;
  position: relative;
  margin-bottom: 155px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.eltaiMember-containter {
  position: relative;
}

.eltaimember-details-important-person {
  position: absolute;
  top: 70%;
  width: 90%;
  height: 187px;
  left: 5%;
  padding: 10px;
  box-shadow: 1px 2px 1px 1px #134b2b54;
  background-color: #ffff;
}

.eltaimember-details-important-person-name {
  color: #134b2B
}

.eltaimember-details-important-person-address {
  font-size: 12px;
}

.ourExecutiveCommittee-containter {
  background-image: url(../../../assets/images/ourExecutiveCommittee/BG.png);
  width: 100%;
  background-size: cover;
}

.presidents-OEC {
  color: #822326
}

.executive-member-title {
  margin-top: 15vh;
  margin-bottom: 5vh;
}

.past-office-beaer {
  margin-top: 5vh;
}

.presidentcard1 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #134b2B;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.presidentcard1 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #6f7b91;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.president-line1 {
  border-left: 4px solid #6f7b91;
  height: 100px;
}

.presidentcard2 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #8dc9cc;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.president-line2 {
  border-left: 4px solid #8dc9cc;
  height: 100px;
}

.presidentcard3 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #e08745;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.president-line3 {
  border-left: 4px solid #e08745;
  height: 100px;
}

.presidentcard4 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #eb6b5c;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.president-line4 {
  border-left: 4px solid #eb6b5c;
  height: 100px;
}

.presidentcard5 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #a0c6a2;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.president-line5 {
  border-left: 4px solid #a0c6a2;
  height: 100px;
}

.presidentcard6 {
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
  background-color: #baa0c6;
  color: white;
  width: 100%;
  padding: 1%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.president-line6 {
  border-left: 4px solid #baa0c6;
  height: 100px;
}

.presidents-dot {
  border: 2px solid white;
  background-color: #ffff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.president-name {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.president-year {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}