.formClass {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	width: 70%;
}

@media (max-width: 1000px) {
	.man {
		display: none !important;
	}
}

#hero {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.login-btn {
	background-color: white !important;
	padding: 10px !important;
	color: #601E1D !important;
	border-radius: '10px';
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.con {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-110px, 17px);
}

/* .table {
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
} */

.login-page {
	background-color: #601E1D;
	min-height: 100vh;
}

.form-content {
	display: grid !important;
	place-items: center !important;
}

.form-content h1 {
	color: white !important;
	font-family: "Lora " !important;
}

.form-content h2 {
	color: white;
	font-weight: 100;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input:-webkit-autofill {
	width: 90%;
	border-radius: 5px;
}

.css-1063wxh-MuiButtonBase-root-MuiIconButton-root:hover {
	background: #B63350 !important;
}

.login-input {
	background-color: #2F0C0B !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
	color: white !important;
	font-size: 1.3rem !important;
}

/* .pass{
	margin-left: 11rem;
} */
.logo {
	position: absolute;
	left: 10%;
	top: 10%;
	transform: translateX(-50%);
	max-width: 100%;
}

.placeholder-color::placeholder {
	color: whitesmoke;
	display: flex;
	text-align: center;
	font-size: 20px;
	font-weight: 200;
}

.hover-link:hover {
	text-decoration: underline !important;
}

@media screen and (min-width:270px) and (max-width:600px) {
	.logo {
		width: 35% !important;
		left: 21% !important;
	}

}

@media screen and (min-width:601px) and (max-width:700px) {
	.logo {
		left: 16% !important;
	}

}

@media screen and (min-width:701px) and (max-width:1100px) {
	.logo {
		left: 14% !important;
	}

}

.login-input .MuiFormHelperText-root {
    color: #e4d3d3 !important;
    background: #601e1d;
    padding: 0;
}

.login-input .MuiFormHelperText-root {
	margin: 0px !important;
}