.react__data__maps svg {
    display: inline-block;
    position: static !important;
    top: 0px;
    left: 0px;
    z-index: -1;
    filter: drop-shadow(-2px 9px 0px white);
}

.HoverInfo {
    display: none !important;
}

.HoverInfo::after {
    display: none !important;
}

.HoverInfo::after:hover {
    display: none !important;
}

.HoverInfo:hover {
    display: none !important;
}

.chapter-heading {
    background-color: rgb(16, 78, 41);
}


.chapter_heading {
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 30px !important;
}

path:hover {
    transform: scale(1);
}

.chapter_card {
    height: 229px;
    border-radius: 23px !important;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0px 3px 10px;
    color: black;
    padding: 39px;
    text-align: center;
    margin: auto;
    float: none;
    margin-top: 140px !important;
}

.chapter_card_text {
    /* color: white; */
    font-size: 20px;
}

a {
    padding-left: 0px;
}

.scrollable-container {
    height: 200px;
    overflow: auto;
}

/* Custom CSS to hide the scrollbar */
.scrollable-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scrollable-container {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

p.no_Chapter {
    font-size: x-large;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.report_icon {
    margin: 0 auto !important;
    color: #0c3e5f;
    display: grid !important;
}

@media screen and (min-width:278px) and (max-width: 375px) {

    p.no_Chapter {
        font-size: 17px;

    }
}

.btn2 {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 2.75rem;
    font-size: 1rem;
    line-height: 2.5;
    border-radius: 2.25rem;
    background: black;
    color: white;
    border-color: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.disable_checkbox {
    pointer-events: none;
    cursor: 'none'
}

.heading {
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    font-weight: 800;
    font-style: italic;
}

.heading::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background-color: #333;
}

.animated {
    animation: slideIn 1s forwards;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.chapter-hori {
    margin-top: 20px !important;
    margin-left: 11px;
}

@media screen and (min-width:280px) and (max-width: 768px) {
    .chapter-with-out-data {
        margin-top: -35% !important
    }
}

.counts {
    background-color: #104e29;
    padding: 19px;
    border-radius: 50%;
    margin: 27%;
}

.chapterDatas {
    border: 1px solid #84a491;
    border-left: none;
    width: 25%;
}

.individualMember {
    border: 1px solid #84a491;
    width: 25%;

}

.institutationalMember {
    border: 1px solid #84a491;
    border-right: none;
    width: 25%;

}

.allChapterData {
    border: 1px solid #1d5835;
    background-color: white;
    width: 80%;
    margin-top: 10%;
}

.viewAllChapters {
    color: #1d5835;
    text-decoration: underline;
}

.instruction {
    background-color: #1d5835;
    padding: 20px;
    color: white;
    margin: 10px;
    width: 47%;
}

@media (max-width: 768px) {
    .instruction {
        width: auto !important;
    }
}

.stateHeading {
    background-color: #1d5835;
    color: white;
    text-align: center;
}

.stateContent {
    background-color: white;
    border-radius: 5px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}

.chapImg {
    border-radius: 9%;
    max-width: 100%;
    max-height: 270px;
    margin-bottom: 20px !important;
}

.ConvenorImg {
    border-radius: 50%;
}

.learnMore {
    color: #1d5835;
}

ul .pagination {
    margin: 0px !important;
}

.incChapter:hover {
    color: white;
}

/* dropdown css */

/* Dropdown */

.dropdown {
    display: inline-block;
    position: relative;
}

.dd-button {
    display: inline-block;
    font-size: 50px;
    font-weight: bolder;
    border-radius: 4px;
    padding: 12px 30px 10px 20px;
    cursor: pointer;
    color: #1d5835;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 78%;
    left: 19%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 21px 12px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -24px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

@media (min-width: 320px) and (max-width: 992.98px) {
    .chapterStatus {
        position: relative;
        left: 41px;
    }

    .chapImg {
        max-width: 100%;
        max-height: 50%;
        margin-bottom: 24px;
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .counts {
        margin: 15%;
    }

    .capterStructure {
        font-size: 12px;
    }

    .stateHover {
        top: 230px !important;
        left: 180px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .stateHover {
        top: 330px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .stateHover {
        top: 319px !important;
        left: 305px !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .stateHover {
        top: 377px !important;
        left: 503px !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .stateHover {
        top: 320px !important;
        left: 363px !important;
    }
}

.stateHover {
    top: 420px;
    left: 390px;
    position: absolute
}

