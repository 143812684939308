@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


body {
  margin: 0;
  font-family: "Lora " !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lora " !important;
}
/* p{
  font-family: Lora !important;
} */

/* h1 h2 h3 h4 h5 h6 {
  font-family: Lora !important;
}  */

.btn-color{
  background: #a52a2a !important;
  font-family: Lora !important;
  color: white !important;

}
.btn-color:hover{
  color: white !important;

}

.MuiTabs-indicator {
  background: #a52a2a !important;
}

.text-colors{
  color: white !important
}

.text-color-head{
  color:#611f1e !important;
}

.css-fcvroy-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #611f1e !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #611f1e !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #611f1e !important;
}