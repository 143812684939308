/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

.join-eltai-bg-image {
    background-image: url(../../../../public/assets/images/AboutPage/abt_bnr1.png);
    width: 100%;
    height: 400px;


}

.Join-eltai-Title-data {
    font-family: "Lora " !important;
    color: black;
    font-weight: 700;
}

.Join-eltai-Title-sub {
    color: black;
    font-size: 18px;
    font-weight: 700;
    background-color: #f5b9b9;
    /* max-width:100%; */
    text-align: center;
    padding: 25px;
    display: inline-block;
}

.Subscribtion-card-heading {
    /* color:  rgb(26, 115, 232); */
    color: rgb(95, 99, 104);
    text-align: center;
    /* padding: 20px; */
    /* 
    border-radius: 50px; */
    display: inline-block;
    font-size: 25px;
    font-weight: 500;
    width: 75%;
    padding: 10x;
    ;
}

.Annual {
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px
}

.card-container1 {
    border: 1px solid #a52a2a;
    width: 100%;
    height: 400px;
    box-shadow: 10px 10px 5px #cccc;
}

.short-term {
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px
}

.long-term {
    color: black;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3;
    margin-top: 20px
}

/* .hover-short-term {
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px
}

.hover-long-term {
    color: white;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3;
    margin-top: 20px
}

.hover-annual-price {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;

} */

.Annual>span {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
}


.short-term>span {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
}


.long-term>span {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
}

.card-container {
    min-width: 100%;
    padding: 20px;
    border: 3px #611f1e solid;
    border-radius: 50px;
    height: 400px;
    box-shadow: 2px 5px #b1b0b1;
    background-color: #fff;
    z-index: 1;
}

/* .hover-card {
    background-color: #178fff;
    border: 3px #178fff solid;
    border-radius: 50px;
    height: 400px;
    transform: scale(1.1);

} */

.hover-subscribtion-title {
    color: white;
    text-align: center;
    padding: 20px;
    border: 4px solid white;
    border-radius: 50px;
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
    width: 75%;
    padding: 10x;
}

/* .hover-annual-price {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    color: white
}

.hover-short-Term-price {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    color: white
} */

.subscribtion-prize {
    color: black !important
}


/* .hover-subscribtion-prize {
    color: white
}

.hover-annual {
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px
} */

.short-term>span {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
}

/* .hover-long-term-price {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    color: white
} */
.important-subscribtion-card {

    top: 64%;
    z-index: -1;

}

.important-news {
    padding: 25px;
    color: #611f1e;
    font-weight: 600;
    font-size: 22px;
    font-family: "Lora " !important;
}

.subcribtion-important-image {
    height: 150px;
    width: 275px;
}

.important-subscribtion-card {
    background-color: #fff;
    z-index: 1;
    text-justify: center;
    text-align: center;
    margin-top: 50px;
}

.Bank-details-title {
    display: flex;
    justify-content: center;
}

.Bank-title {
    /* word-spacing: 30px; */
    font-family: "Lora " !important;
    color: #763030
}

.bankNameDetails {
    background-color: #611f1e;
    border-radius: 15px;
    color: white;
    text-align: center;
    font-size: 18px;
    /* font-weight: 400; */
    font-family: "Lora " !important;

}


.bankName-details {
    margin-top: 35px;
    border: 2px solid #611f1e;
    margin-left: 20px;
    width: 60%;
    border-radius: 10px;
    box-shadow: 3px 3px #b1b0b1;
    color: #611f1e;
    font-size: 15px;
    font-weight: 700;
    font-family: "Lora " !important;
    word-spacing: 5px;

}

.membercard-container1 {
    border: 1px solid #a52a2a;
    width: 100%;
    height: 300px;
    /* border-radius: 10px; */
    box-shadow: 10px 10px 5px #cccc;
    overflow: auto;
    /* Allow scrolling */
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

/* Hide scrollbars in Webkit browsers (Chrome, Safari, etc.) */
.getall_customScroll::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 360px) and (max-width: 560px) {
    .bankName-details {
        width: 100%;
        margin-left: -1px;
    }

    .bank-details-card-body {
        width: 100%;
        height: auto !important;
    }
}

@media only screen and (max-width: 280px) {
    .bankName-details {
        width: 100%;
        margin-left: 1px;
    }

    .bank-details-card-body {
        width: 190%;
        margin-left: -85px;
    }
}

.card_join {
    border: 1px solid !important;
}