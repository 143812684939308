/* p.para {
    color: black;
    display: flex;
    justify-content: center;
} */

.text{
    color: black;
}
.job-desc{
    margin-inline: auto;
    width: 80%;
}
.exp{
    margin-inline: auto;
}
.package{
    font-size: 20px;
    /* margin-inline: auto; */

}
.cbtn{
    padding: 8px 45px 8px 45px;
    border: 1px solid;
    border-radius: 7px;
}
.card{
    border-left: none;
    /* border-radius: 10px; */
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}
.all-job{
    height:700px;
    overflow-y: scroll;
    border: 1px solid #00000026;
    border-left: none;
    border-radius: 7px;
    /* filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25)); */
}
.all-job::-webkit-scrollbar {
    width: 0;  /* This will hide the scrollbar */
    background: transparent; /* Optional: Makes scrollbar area transparent */
}
.job-page{
    background:linear-gradient(#F5F5F5,#B2E2F9);
}
.jpg-img{
    border: 11px solid #F4F4F4;
    border-radius: 63px;
    padding: 14px;
    margin-inline: auto;
}
.apply{
    padding: 8px 45px 8px 45px;
    background-color: #1769aa;
    color: white;
    border-radius: 7px;

}
.btn-content{
    align-content: end;
    padding-bottom: 10px;
    padding-right: 10px;
}
.save{
    border: 1px solid;
    border-radius: 50px;
    font-size: 40px !important;
    padding: 10px;


}
.dot{
    border: 1px solid;
    border-radius: 50px;
    font-size: 40px !important;
    padding: 10px;
}
.line{
    gap: 10px;
}
.time-zone{
    align-content: center;
}
.company-card{
    height: 112%;
}
.requirements-card{
    height: 112%;
}
