.News-details {
    font-family: "Lora " !important;
    font-size: 16px;
    color: black;
    letter-spacing: -1px;
    line-height: 1.3;
    text-align: justify;
    max-width: 88%;
}

.custom-header_news {

    font-family: 'Signika', sans-serif;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    white-space: nowrap;

}
.viewNews{
    color: white;
    font-size: x-large;
    cursor: pointer;
}
.News-img {
    border-radius: 25px;
    box-shadow: 5px 7px #dddddd;

}

.news-container {
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    margin: auto 0;
}

.news-title {
    font-family: "Lora " !important;
    font-weight: 500;
    color: black
}

.news-title-span {
    font-weight: bolder
}

.css-19r6kue-MuiContainer-root {
    width: 88%;
}

.custom-header {
    font-family: signika;
    color: #104e29;
    font-weight: bold;
    margin-top: 20px;
}

@media (max-width: 575.98px) {
    .custom-header {
        font-size: x-large;
        margin-top: 15px;
    }
}