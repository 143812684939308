.bg_clr:before {
    background-color: #0f5b8c;
}

.bg_clr {
    height: auto !important;
}

/* .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 123px;
    z-index: 1020;
    max-width: 80%;
    margin-inline: auto;
    background-color: #176492d6;
    border-radius: 6px;
} */

.header-containter {
    top: 0px;
    position: sticky !important;
    z-index: 12999999999999;
    background-color: #d2dcc6;
    /* background-color: #104E29; */
    height: 50px
}

.sticky {
    position: sticky;
    top: 50px;
    z-index: 1020;
    max-width: 102%;
    margin-inline: auto;
    background-color: #f5fafc;
}

.navbar-nav .nav-link.show {
    color: red !important;
}

/* a.nav-link {
    color: black !important;
} */

/* .navbar-dark .navbar-nav .nav-link {
    color: rgb(255 255 255);
} */

.logo_psn {
    display: flex;
    justify-content: center;
}

.me-auto {
    margin-inline: auto;
}

a .nav {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.marquee {
    width: 100%;
    background-color: #f2f2f2;
    padding: 10px 0;
    overflow: hidden;
}

.marquee p {
    white-space: nowrap;
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

p {
    color: black !;
}


@media only screen and (max-width: 961px) {
    a.nav {
        padding-left: 11px !important;
        padding-right: 11px !important;
    }
}

@media only screen and (max-width: 817px) {
    a .nav {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

.dropdown-menu {
    padding: 15px;
}

a.dropdown-item {
    padding-right: 0px !important;
}

.dropdown-menu {
    display: none;
}

.navbar-nav .dropdown:hover .dropdown-menu:hover {
    display: block;
    margin-top: 0;
    /* remove the gap so it drops down neatly */
    font-weight: 600;
    /* background-image: url(../../assets/images/banner/menu-bg.png); */
}

/* .navbar-nav .dropdown:hover .dropdown-menu {
 display: none;
} */
/*  */

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
    color: #ff0400 !important;
    background-color: transparent;
    font-weight: 600;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #ff0400 !important;
    background-color: transparent !important;
    font-weight: 600 !important;
}

/* customStyles.css */
.custom-nav-link {
    color: red;
    text-transform: none !important;
    font-weight: 600;

}

/* .custom-nav-dropdown .dropdown-item {
    color: red;
} */

.custom-nav-dropdown>.dropdown-toggle {
    color: #eaf0f2 !important;
    /* color: #723332 !important; */
    /* Maintain the original text color */

}


.custom-nav-dropdown>.dropdown-toggle:hover,
.custom-nav-dropdown>.dropdown-toggle:active {
    background-color: transparent !important;
    color: #ff0400 !important;
}

.custom-nav-dropdown>.dropdown-toggle:focus {
    color: #eaf0f2 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #ff0400 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #ff0400 !important;
}

.custom-nav-link,
.custom-nav-dropdown .dropdown-item {
    font-weight: 600;
    font-size: 17px !important;

}

.custom-nav-dropdown {
    padding-left: 1px !important;
    padding-right: 1px !important;
}

.custom-subnav-dropdown {
    left: 95%;
    top: 1pc;
}

.eltai-home-nav-bar {
    font-weight: 600;
    text-transform: none;
    font-family: 'poppins';
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.eltai-home-nav-bar a {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

/* 
.scrolled .custom-nav-link,
.scrolled .custom-nav-dropdown .dropdown-item {
    color: #611f1e;
    font-weight: 600;
    font-size: 17px !important;


} */

.scrolled .custom-nav-link,
.scrolled .custom-nav-dropdown .dropdown-item:hover {
    /* color: white; */
    font-weight: 600;
    font-size: 17px !important;


}

.scrolled .nav-link {
    /* color: White; */
    font-weight: 600;
    font-size: 15px !important;


}

.nav-link {
    /* color: #ff0400; */
    color: #eaf0f2 !important;
    /* color: #723332; */
    font-weight: 600;
    font-size: 15px !important;


}

.menu-btn {
    background-color: #f2f2f2 !important;
}

/* .social-menu {
    padding: 21px;
}

@media (max-width: 575.98px) {
    .social-menu {
        padding: 0px;
    }
} */

.social-menu ul {
    /* position: absolute; */
    /* top: 54%;
    left: 11%; */
    padding: 0;
    margin: 0;
    /* transform: translate(-50%, -50%); */
    display: flex;
    z-index: 12;
}

@media (max-width: 730px) {
    .social-menu ul {
        left: 14%;
    }
}

.social-menu ul li {
    list-style: none;
    /* margin: 0 12px; */
    margin: 0 4px;
}

/* .social-menu ul li .fab{
    font-size: 14px;
    line-height: 14px;
    transition: .3s;
    color: #000;
} */
.social-menu ul li .fab {
    font-size: 14px;
    line-height: 34px;
    transition: .3s;
    color: #000;
    margin-left: 8px !important;
}

.social-menu ul li .fab:hover {
    color: #fff;
}

.social-menu ul li a {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* background-color: #fff; */
    text-align: center;
    transition: .6s;
}

.social-menu ul li a:hover {
    transform: translate(0, -5%);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.fa-youtube:before {
    color: white;
}

.fa-twitter:before {
    color: white;
}

.fa-instagram:before {
    color: white;
}

.fa-facebook:before {
    color: white;
}

/* .social-menu ul li:nth-child(1) a:hover {
    background-color: rgb(0, 106, 255);
}

.social-menu ul li:nth-child(2) a:hover {
    background-color: #E4405F;
}

.social-menu ul li:nth-child(3) a:hover {
    background-color: red;
}

.social-menu ul li:nth-child(4) a:hover {
    background-color: #000;
} */

.sticky {
    /* background-image: url(../../assets/images/banner/menu-bg.png); */
    background: #104e29;
}

.nav-link:focus,
.nav-link:hover {
    /* color: rgb(0 114 187) !important; */
    color: #ff0400 !important;
}

/* .MuiAvatar-root {
    background-color: #f13700 !important;
} */

/* Login Button design */

/* .login-icon {
    background-color: brown;
    padding: 7px;
    border-radius: 5px;
    border: 2px solid white;
} */

.top_nav_btn {
    border-radius: 0px !important;
    background-color: #104e29 !important;
    color: #ffffff !important;
    /* top: -1px !important; */
    padding: 2px !important;
}

/* Join Button design */
.top_nav_btn_join {
    background-color: #104e29 !important;
    color: #ffffff !important;
    padding: 2px 15px 3px 12px !important;
    border-radius: 0px !important
    /* top: 10% !important; */
}

/* Social Media Configuration */
.icon-config::before {
    font-size: 17px !important;
    color: #104E29;
    background-color: white;
    padding: 6px 10px 6px 10px;
    border-radius: 50%;
    border: 1px solid #104E29;
}

.icon-config-insta::before {
    font-size: 17px !important;
    color: #104E29;
    background-color: white;
    padding: 6px 8px 6px 8px;
    border-radius: 50%;
    border: 1px solid #104E29;
}

.icon-config-utube::before {
    font-size: 17px !important;
    color: #104E29;
    background-color: white;
    padding: 6px 7px 6px 7px;;
    border-radius: 50%;
    border: 1px solid #104E29;
}

.icon-config-twitter::before {
    font-size: 17px !important;
    color: #104E29;
    background-color: white;
    padding: 6px 8px 6px 8px;
    border-radius: 50%;
    border: 1px solid #104E29;
}

@media (max-width: 575px) {

    .social-menu ul {
        display: none;
    }

    /* .marker{
        padding-left: 0% !important;
    } */

}

@media screen and (min-width: 992px) and (max-width: 1250px) {

    .custom-nav-link,
    .custom-nav-dropdown .dropdown-item {
        font-weight: 600;
        font-size: 16px !important;

    }

    .custom-nav-dropdown {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .eltai-home-nav-bar {
        font-weight: 600;
        text-transform: none;
        font-family: 'poppins';
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .eltai-home-nav-bar a {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .scrolled .custom-nav-link,
    .scrolled .custom-nav-dropdown .dropdown-item {
        color: #611f1e;
        font-weight: 600;
        font-size: 16px !important;


    }

    .scrolled .custom-nav-link,
    .scrolled .custom-nav-dropdown .dropdown-item:hover {
        /* color: white; */
        font-weight: 600;
        font-size: 16px !important;


    }

    .scrolled .nav-link {
        /* color: White; */
        font-weight: 600;
        font-size: 16px !important;


    }

    .nav-link {
        /* color: #ff0400; */
        color: #723332;
        font-weight: 600;
        font-size: 15px !important;


    }

    .scrolled .custom-nav-link,
    .scrolled .custom-nav-dropdown .dropdown-item {
        color: #611f1e;
        font-weight: 600;
        font-size: 16px !important;


    }

    .scrolled .custom-nav-link,
    .scrolled .custom-nav-dropdown .dropdown-item:hover {
        /* color: white; */
        font-weight: 600;
        font-size: 16px !important;


    }

    .scrolled .nav-link {
        /* color: White; */
        font-weight: 600;
        font-size: 16px !important;


    }

}


.social-media-footer-icon-header {
    font-size: 30px !important;
    border: 4px solid white;
    color: #235638;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #104E29;
    padding: 4px;

}

.twitter-list {
    position: relative;
    top: -3px;

}

@media screen and (max-width: 993px) {
    .mobile-style {
        height: 260px !important;
        overflow-y: scroll;
    }

    .navbar-toggler {
        height: 51px;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    /* Adjust the height of the entire navbar for small screens */
    .navbar {
        height: 50px;
        /* Set a smaller height for the navbar itself */
        padding: 0 10px;
        /* Adjust padding */
    }
}

@media (min-width: 992px) {

    /* Main dropdown menu styles */
    .custom-nav-dropdown .dropdown-menu {
        position: relative;
        /* For main dropdown positioning */
    }

    /* Sub-dropdown menu (appears when hovering over a parent item) */
    .custom-nav-dropdown .dropdown-menu .dropdown-menu {
        position: absolute;
        top: 2px !important;
        left: 92%;
        right: auto;
        /* Align the submenu to the left of the parent */
        margin-left: -1px;
        /* Optional: align the sub-menu tightly */
        opacity: 1;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    .dropdown-right.custom-nav-dropdown .dropdown-menu .dropdown-menu {
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        margin-left: -1px;
        opacity: 1;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    .custom-nav-dropdown .dropdown-menu .dropdown-menu:active {
        color: #ff0400
    }

    .custom-nav-dropdown .dropdown-item:hover>.dropdown-menu.show {
        color: #ff0400
    }

    /* Show sub-menu on hover of the parent item */
    .custom-nav-dropdown .dropdown-item:hover>.dropdown-menu {
        content: ' ▶';
        visibility: visible;
        opacity: 1;
        /* display: block; */
        /* Ensure the submenu displays */
    }

    /* Show sub-menu on hover */
    .custom-nav-dropdown .dropdown-item:hover>.dropdown-menu {
        visibility: visible;
        opacity: 1;
        display: block;
        /* Ensure the submenu displays */
    }

    /* Add an arrow for the dropdown if needed */
    /* .custom-nav-dropdown .dropdown-item::after {
        float: right;
        padding-left: 5px;
    } */

    /* Optional: Add padding/margin adjustments to dropdown items */
    .custom-nav-dropdown .dropdown-item {
        padding: 8px 16px;
    }

}

/* @media (min-width: 992px) {
    .social-menu ul {
        left: 9% !important;
    }
} */
/* .drpdown_left .dropdown-menu {
    left: -50px !important;
    right: auto;
    transform: translateX(-15%);
} */
@media (min-width:1431px) and (max-width:1450px) {
    .drpdown_left .dropdown-menu {
        left: -90px !important;
        right: auto;
        transform: translateX(-15%);
    }

}
@media (min-width:1450px) and (max-width:1500px) {
    .drpdown_left .dropdown-menu {
        left: -70px !important;
        right: auto;
        transform: translateX(-15%);
    }

}
@media (min-width:1500px) and (max-width:1665px) {
    .drpdown_left .dropdown-menu {
        left: -50px !important;
        right: auto;
        transform: translateX(-15%);
    }

}
@media (min-width:1200px) and (max-width:1300px) {
    .drpdown_left .dropdown-menu {
        left: 0px !important;
        right: auto;
        transform: translateX(-15%);
    }

}

@media (min-width:992px) and (max-width:1170px) {
    .drpdown_left .dropdown-menu {
        left: 0px !important;
        right: auto;
        transform: translateX(-45%);
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .social-menu ul {
        left: 13% !important;
    }
}

@media (min-width: 575px) and (max-width: 768px) {
    .social-menu ul {
        left: 17% !important;
    }
}

@media (min-width:992px) and (max-width:1030px) {
    .dropdown-right .dropdown-menu {
        position: relative;
        left: -105%;
    }
}

.dropdown-menu {
    top: 94% !important;
}


@media (min-width: 992px) and (max-width:1172px) {
    .dropdown-right.custom-nav-dropdown .dropdown-menu .dropdown-menu {
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        /* margin-left: -155px; */
        margin-left: -82px;
        opacity: 1;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }
}
@media (min-width: 1200px) and (max-width:1300px) {
    .dropdown-right.custom-nav-dropdown .dropdown-menu .dropdown-menu {
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        margin-left: -130px;
        opacity: 1;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    cursor: default;
    padding: 6px 14px !important;
}

/* button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-137ul40-MuiButtonBase-root-MuiIconButton-root {
    padding: 5px !important;
    border-radius: 50% !important;
} */

.header-menu-btn {
    padding: 5px !important;
    border-radius: 50% !important;
}


@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: center;
    }
}

@media (min-width: 1030px) and (max-width: 1430) {
    a.p-0.me-4.nav-link {
        position: absolute;
        left: 3%;
    }
}

@media (min-width: 1031px) {
    a.p-0.me-4.nav-link {
        position: absolute;
        left: 1%;
    }
}

@media (min-width: 992px) and (max-width: 1029) {
    a.p-0.me-4.nav-link {
        position: absolute;
        left: 1%;
    }
}

/* 
@media (min-width: 992px) and (max-width: 1430) {

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
        color: #ff0400 !important;
    }

    .custom-nav-dropdown>.dropdown-toggle:hover,
    .custom-nav-dropdown>.dropdown-toggle:active {
        background-color: transparent !important;
        color: #ff0400 !important;
    }

    .custom-nav-dropdown>.dropdown-toggle:focus {
        color: #723332 !important;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
        color: #ff0400 !important;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
        color: #ff0400 !important;
    }
} */

/* @media (min-width:992px) and (max-width:1030px) {
    .dropdown-right .dropdown-menu {
        position: relative;
        left: -105%;
    }
}
  */

.navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

 /* span.nestedurl {
    color: red;
}  */

.navbar-dark .navbar-nav .nav-item .dropdown-menu .nav-link {
    color: black !important;
}

.navbar-dark .navbar-nav .nav-item .dropdown-menu .nav-link:active {
    color: red !important;
}

.topSection{
    padding: 0 74px;
}

@media (max-width: 991.98px) { 
    .topSection{
        padding: 0 46px;
    }
 }

 @media (max-width: 767.98px) { 
    .topSection{
        padding: 0 20px;
    }
  }
 @media (max-width: 992px) { 
    .profileAvatar{
        right: 46px;
    }
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
    margin: 0px;
}

