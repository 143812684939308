th {
    text-align: center;
    vertical-align: middle; /* Ensures vertical alignment for multi-row spans */
  }
  .individual-teacher-memberships{
    background-color:#D9EAD3 !important ;
    color:#000 !important
  }
  .individual-student-memberships{
    background-color: #CFE2F3 !important;
    color: #000 !important;
  }
  .individual-institution-memberships{
    background-color: #EAD1DC !important;
   color: #000 !important;
  }
  .individual-corporatee-memberships{
    background-color: #FFF2CC !important;
    color: #000 !important;
  }
  .totel-pdf-state-management{
   background-color: #FCE5CD !important;
   color: #000 !important;
   font-weight: bold !important;

  }
  .no-of-totel-pdf-state-management{
    background-color: #B6D7A8 !important;
    color: #000 !important;
    font-weight: bold !important;
   }
   .name-state-background{
    background-color: #ced1d3 !important;
   }
   .border-bottom-state-wise-pdf{
    border-bottom: 5px solid white !important;
   }
   .name-state-wise-background{
    background-color: #acb5d0  !important;
   }
   .table-responsive {
    border: 1px solid #ccc;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
}
@media (max-width: 767px) {
  .menus-color {
      font-size: 12px;
      width: 100%;
      padding: 10px;
  }

  .table-responsive {
      overflow-x: auto;
  }

  .table th, .table td {
      font-size: 10px; /* Smaller font size for smaller screens */
      white-space: normal; /* Allow text to wrap in small screens */
  }

  .table th {
      padding: 8px;
  }
  
  .table td {
      padding: 6px;
  }
}