@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');

.signika-font-aboutUs {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  /* font-style: normal; */

}

.header-who-are-we {
  color: #844936
}

.about-us-page-card1 {
  border: 4px solid #66bee8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #66bee8;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border: 5px solid white;
  border-radius: 50%;
  max-width: 90%;
  box-shadow: 0px 2px 4px gray;
}

.about-us-page-card1-containter {
  position: relative;
  /* border:5px solid white; */
  top: -65px;
}

.about-us-page-card1-caption {
  border: 5px solid #66bee8 !important;
}

.about-us-page-card2 {
  border: 4px solid #233e2d;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #233e2d;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border: 5px solid white;
  border-radius: 50%;
  max-width: 90%;
  box-shadow: 0px 2px 4px gray;
}

.about-us-page-card2-containter {
  position: relative;
  /* border:5px solid white; */
  top: -65px;
}

.about-us-page-card2-caption {
  border: 5px solid #233e2d !important;
}

.about-us-page-card3 {
  border: 4px solid #751718;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #751718;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border: 5px solid white;
  border-radius: 50%;
  max-width: 90%;
  box-shadow: 0px 2px 4px gray;
}

.about-us-page-card3-containter {
  position: relative;
  /* border:5px solid white; */
  top: -65px;
}

.about-us-page-card3-caption {
  border: 5px solid #751718 !important;
}

.about-us-icon-caption {
  margin-left: -10%;
  /* margin-top: -12px; */
}

.About-us-header {
  background-color: #134b2b;
  color: #fff;
  height: 70px;
  margin-top: 7%;

}

.gallery-hori {
  margin-left: 25px !important;
  margin-bottom: 20px !important;
  border: none;
  border-bottom: 2px solid #e93602 !important;
  padding: 0;
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .about-us-page-card2-containter {
    margin-top: 40px;
  }

  .about-us-page-card3-containter {
    margin-top: 82px;
  }

  .about-us-icon-caption {
    margin-left: 0px !important;
  }

}

@media screen and (min-width: 751px) and (max-width: 978px) {
  .gallery-hori {
    width: 70% !important;
    margin-left: 5px !important;
  }

}

@media screen and (max-width: 270px) {
  .gallery-hori {
    width: 30% !important
  }
}

@media screen and (min-width: 270px) and (max-width: 400px) {
  .gallery-hori {
    width: 45% !important
  }
}

@media screen and (min-width: 403px) and (max-width: 600px) {
  .gallery-hori {
    width: 60% !important
  }
}

@media screen and (min-width: 601px) and (max-width: 750px) {
  .gallery-hori {
    width: 74% !important;
    margin-left: 22px !important;
  }
}

@media screen and (min-width: 978px) and (max-width: 1100px) {
  .gallery-hori {
    margin-left: 10px !important;
  }

}