.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    /* margin-top: 30px;
    margin-bottom: 66px; */
  }
  
  .pagination li {
    margin: 0 5px;
    display: inline-block;

  }
  
  .pagination li a {
    color: black !important;
    text-decoration: none;
    padding: 8px 16px !important;
    /* border: 1px solid #0072bb; */
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination li.active a {
    background-color: #a52a2a;
    color: white !important;
  }
  
  .pagination li a:hover {
    background-color: #a52a2a;
    color: white !important;
  }
  
  ul.pagination {
    margin: 0;
    padding-bottom: 10px;
}