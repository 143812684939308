@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');

.signika-font-startingchapter {
  font-family: "Signika", sans-serif;
  font-size: 16px;
  text-align: justify;
  font-optical-sizing: auto;
  font-weight: 350;
  margin: 10px;
}

.starting-chapter-header-font {
  font-family: "Signika", sans-serif;
  font-weight: 600 !important;
  color: #233e2d;
}

.starting-chapter-firstpara {
  font-size: 18px;
}

.starting-chapter-link {
  display: inline;
  color: #233e2d;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.starting-chapter-boldpara {
  font-weight: 500;
}

.starting-chapter-para {
  margin-left: 30px;
}

.starting-chapter-para-align {
  margin-bottom: 20px;
}

.starting-chapter-text {
  font-weight: 500;
}

.starting-chapter-grid-container {
  font-family: "Signika", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 5px 10px 70px !important;
  display: flex;
  justify-content: center;
  /* Center the grid horizontally */
  align-items: center;
  /* Center the grid vertically */
  height: auto;
  /* Full height of the viewport */
  box-sizing: border-box;
}

/* Row Styling */
.starting-chapter-grid-container .row {
  display: flex;
  justify-content: center;
  /* Center the columns horizontally */
  align-items: center;
  /* Center the content vertically */
  flex-wrap: wrap;
  /* Ensure the content wraps if needed */
  width: 100%;
  /* Full width to ensure alignment */
  max-width: 1200px;
  /* Max width for the grid layout */
}

.starting-chapter-grid-container .col {
  flex: 1;
  /* Allow the columns to grow equally */
  padding: 20px;
  text-align: center;
  /* Center the text within each column */
}

/* Button Styling */
.starting-chapter-grid-container button {
  font-family: "Signika", sans-serif;
  color: white;
  background-color: #233e2d;
  padding: 15px 30px;
  /* Add horizontal padding */
  font-weight: 600;
  border-radius: 25px;
  font-size: 15px;
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover */
}

.background {
  background-image: url('../../../assets/images/chapter/Background-page.png');
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
}