/* .event{
    background-image: url("../../../public/assets/images/Event/1720x1080-wbg.png");
}

.event_col_8{
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 70px;
}

.event_card {
    padding: 24px;
    background-color: #0072bb;
    border-radius: 16px;
    box-shadow: 3px 5px 7px #1d1c1c;
    width: 763px;
}

.event_image_card{
    background-color:rgb(254 191 2);
    border-radius: 12px;
    display: flex;
    justify-content: center;
}

.event_col_4{
    padding: 50px;
}
.event_heading_col_4{
    color: black;
    font-size: 75px;
    font-weight: 700;
}

.event_p_col_4{
    font-size: 23px;
    color: #244c69;
    font-weight: 600;
}

.event_heading{
    text-align: center;
    padding: 40px;
    color: black;
    font-weight: 700;
    font-size: 30px !important;
    font-family: Poppins !important;
}

.event_text_card{
    margin: auto;
    text-align: center;   
}

.event_text_card_heading{
    color: white;
    font-size: 33px;
    font-weight: 500;
}

.event_btn_col_4{
    background: #0072bb;
    color: white;
    border-radius: 14px;
    padding: 5px;
    border: none;
    box-shadow: 3px 5px 7px #1d1c1c;
    width: 136px;
}

.event_btn_col_8{
    background: #0072bb;
    color: white;
    border-radius: 14px;
    padding: 5px;
    border: none;
    box-shadow: 3px 5px 7px #1d1c1c;
    width: 136px;
    float: right;
    margin-right: 160px;
    margin-bottom: 142px;
    font-size: 21px;
    font-weight: 600;
}
@media screen and (min-width: 767px) and (max-width: 960px) {
.event_heading_col_4{
    font-size: 65px;
}
}
@media screen and (min-width: 280px) and (max-width: 560px) {
    .event_heading_col_4{
        font-size: 45px;
    }
    .event_heading{
        padding:5px
    }
    } */


.event {
    height: 30%;
    width: 80%;
}

.event_img {
    width: 100%;
    height: 100%;
}

.img_clm {
    padding: 0px;
}

.event_title {
    padding-left: 10px;
    font-weight: 800;
    font-family: "Lora " !important;
}

.event_content {
    color: black;
}

.text-area {
    border-top: 4px solid black;
    border-right: 4px solid black;
    border-bottom: 4px solid black;
    border-top-right-radius: 10px;
}

.date_sec {
    border-top-left-radius: 10px;

}

.event_heading {
    text-align: center;
    /* padding: 20px; */
    color: black;
    font-weight: 800;
    font-size: 30px !important;
    /* font-family: Poppins !important; */
}

.event_container {
    height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
}


/**
* VARIABLES
**/

@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Lora " !important;
    font-size: 16px;
    background-color: #eee;
}


/**
* CARD
**/

.Eventcard {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
    transform: translateX(1%) translateY(0%) translateZ(0);
    width: 370px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
    overflow: hidden;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
}

.Eventcard a {
    color: inherit;
    text-decoration: none;
}

.Eventcard:hover {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}


/**
* DATE
**/

.Eventcard__date {
    position: absolute;
    top: 0%;
    width: 90px;
    height: 80px;
    padding-top: 2px;
    background-color: #f03700ba;
    color: #fff;
    text-align: center;
    font-weight: 700;
    line-height: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Eventcard__date__month {
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 18px;
}

.Eventcard__date__day {
    font-size: 28px;
}

/**
* THUMB
**/

.Eventcard__thumb {
    height: 245px;
    overflow: hidden;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.Eventcard__thumb img {
    width: 100%;
    display: block;
    opacity: 1;
    object-fit: scale-down;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.Eventcard:hover .Eventcard__thumb {
    height: 130px;
}

.Eventcard:hover .Eventcard__thumb img {
    opacity: 0.6;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}


/**
* Eventcard_BODY
**/

.Eventcard__body {
    position: relative;
    height: 115px;
    padding: 20px;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.Eventcard:hover .Eventcard__body {
    height: 300px;
}

.Eventcard__category {
    position: absolute;
    top: -25px;
    left: 0;
    height: 25px;
    padding: 0 15px;
    background-color: #611f1e;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 25px;
}

.Eventcard__title {
    margin: 0;
    padding: 0 0 10px 0;
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
}

.Eventcard__subtitle {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 19px;
    color: #611f1e;
}

.Eventcard__description {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 60px;
    top: 45px;
    margin: 0;
    padding: 0;
    color: #666C74;
    line-height: 27px;
    opacity: 0;
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 11px;
}

.Eventcard:hover .Eventcard__description {
    opacity: 1;
    /* -webkit-transform: translateY(0px); */
    /* transform: translateY(0px); */
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.Eventcard__footer {
    position: absolute;
    bottom: 12px;
    left: 20px;
    right: 20px;
    font-size: 11px;
    color: #A3A9A2;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 2px;
    font-size: 18px;
}

.icon+.icon {
    padding-left: 10px;
}

.onclick {
    cursor: pointer;
}









.animate {
    font-size: 32px;
    margin: 0px 0 0;
    /* border-bottom: 2px solid #ccc; */
    font-weight: 900;

}

.animate span {
    display: inline-block;
}

.animate span:nth-of-type(2) {
    animation-delay: .05s;
}

.animate span:nth-of-type(3) {
    animation-delay: .1s;
}

.animate span:nth-of-type(4) {
    animation-delay: .15s;
}

.animate span:nth-of-type(5) {
    animation-delay: .2s;
}

.animate span:nth-of-type(6) {
    animation-delay: .25s;
}

.animate span:nth-of-type(7) {
    animation-delay: .3s;
}

.animate span:nth-of-type(8) {
    animation-delay: .35s;
}

.animate span:nth-of-type(9) {
    animation-delay: .4s;
}

.animate span:nth-of-type(10) {
    animation-delay: .45s;
}

.animate span:nth-of-type(11) {
    animation-delay: .5s;
}

.animate span:nth-of-type(12) {
    animation-delay: .55s;
}

.animate span:nth-of-type(13) {
    animation-delay: .6s;
}

.animate span:nth-of-type(14) {
    animation-delay: .65s;
}

.animate span:nth-of-type(15) {
    animation-delay: .7s;
}

.animate span:nth-of-type(16) {
    animation-delay: .75s;
}

.animate span:nth-of-type(17) {
    animation-delay: .8s;
}

.animate span:nth-of-type(18) {
    animation-delay: .85s;
}

.animate span:nth-of-type(19) {
    animation-delay: .9s;
}

.animate span:nth-of-type(20) {
    animation-delay: .95s;
}


.seven span {
    color: #0072bb;
    opacity: 0;
    transform: translate(-150px, 0) scale(.3);
    animation: leftRight .5s forwards;
}


@keyframes leftRight {
    40% {
        transform: translate(50px, 0) scale(.7);
        opacity: 1;
        color: #348c04;
    }

    60% {
        color: #0f40ba;
    }

    80% {
        transform: translate(0) scale(2);
        opacity: 0;
    }

    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
}

.heading {
    /* font-family:"Lora " !important; */
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    font-weight: 800;
    font-style: italic;
}

.heading::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background-color: #333;
}

.animated {
    animation: slideIn 1s forwards;
    color: #333;
    /* Change text color */
    /* font-family:"Lora " ; */
    font-size: 24px;
    /* Change font size */
    font-weight: bold;
    /* Change font weight */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Add text shadow */
}

.hori-line {
    margin-top: 21px !important;
    margin-left: 11px;
}

.upcoming-class {
    background-color: #104e29;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Signika', sans-serif;
    font-size: 2.5rem;
    font-weight: 800;
    padding: 10px 0;
}

.upcoming-class-div {
    display: flex;
    flex-direction: row;
    width: 90%;
    background-color: #eaeaea;
    border-radius: 0px;
    margin: 0 auto;
    padding: 20px;
}

.upcoming-class-div-1 {
    padding-right: 60px;
}

.upcoming-class-p {
    color: #104e29;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0;
}

.upcoming-class-p-1 {
    font-size: 1rem;
    float: inline-end;
}

.upcoming-class-img-div {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
}

.upcoming-class-p-2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: -55px;
}

.upcoming-class-p-3 {
    font-size: 1.1rem;
    line-height: 1.4;
    margin-left: -55px;
    text-align: justify;
}

.vertical-line {
    border-left: 1px solid green !important;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 155px;
}

.row.align-items-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
}

span.upcoming-class-button {
    border: 1px solid green;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 0px;
    font-size: 0.9rem;
    margin-right: 10px;
    background-color: transparent;
    float: inline-end;
    color: rgb(16, 78, 41)
}

.upcoming-horizontal-line {
    border-top: 2px solid green;
    margin: 20px auto;
    width: 90%;
}

/* Responsive Styling */
@media screen and (min-width:768px) and (max-width: 991px) {
    .upcoming-class-p {
        font-size: 1.25rem;
        padding-left: 0;
        text-align: center;
    }

    .upcoming-class-p-1 {
        padding-left: 0;
        text-align: center;
    }

    .upcoming-class-img-div {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
    }

    .vertical-line {
        border-left: 1px solid green !important;
        display: block;
        position: relative;
        margin-left: -20px;
        margin-right: auto;
    }

    .upcoming-class-div-1 {
        margin-left: 0;
    }

    .upcoming-class-div {
        padding: 15px;
        width: 90%;
    }
}

@media screen and (min-width:339px) and (max-width:767px) {

    .upcoming-class-p-2 {
        margin-left: 0px !important;
        text-align: center;
    }

    .upcoming-class-p-3 {
        margin-left: 0px !important;
        text-align: justify;
    }

    .upcoming-class-div-1 {
        padding-right: 22px;
        padding-left: 0px;
    }

    .upcoming-class-p-1 {
        float: none
    }

    .vertical-line {
        height: 0px !important
    }
}

@media screen and (min-width:200px) and (max-width:349px) {
    .upcoming-class-div-1 {
        padding-right: 0px;
        padding-left: 13px;
    }

    .upcoming-class-p-2 {
        margin-left: 0px !important;
        text-align: center;
    }

    .upcoming-class-p-3 {
        margin-left: 0px !important;
        text-align: justify;
    }

    .upcoming-class-p-1 {
        float: none
    }

    .vertical-line {
        height: 0px !important
    }
}