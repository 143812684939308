@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');

.inter {
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: 'futura-bold';
  src: local('futura-bold'),
    url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
}

.SigEltai-paragraph {
  text-align: justify;
  font-size: 14px;
}

.SigEltai-Green {
  color: #104E29
}

.eltai-sig-image-card {
  border: 3px solid #104E29 !important;
  background-color: #D9D9D9;
  height: 30px;
}

.eltai-sig-image {
  margin-top: -30px;
}

.eltai-sig-context-card {
  border: 3px solid #104E29 !important;
}

.eltai-sig-button-card {
  background-color: #104E29;
  border:0;
  border-radius:0;
}
.eltai-sig-button-card:hover{
  background-color: #104E29;
}

.SigEltai-paragraph {
  font-size: 18px;
}

.eltaiSig-card {
  border: 3px solid #104E29 !important;
  box-shadow: 10px 10px 5px #b1cbc2;
}

@media (min-width: 768px) and (max-width: 992px) { 
  .eltai-sig{
    margin: 0px 86px;
  }
 }