.badgescard,
.firstinfosig {
    display: flex;
    justify-content: center;
    align-items: center;
}



*,
*:before,
*:after {
    box-sizing: border-box;
}

.contentsig {
    position: relative;
    animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
}

.individualsig_card {
    min-height: 100px;
    padding: 20px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.individualsig_card:after {
    content: '';
    display: block;
    width: 40%;
    height: 300px;
    background: #611f1e;
    position: absolute;
    animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
}

.badgescard {
    padding: 10px 20px;
    border-radius: 3px;
    background-color: #611f1e;
    color: #fff;
    width: 480px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: 10px;
    animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards;
}

.badgescard span {
    font-size: 1.6em;
    margin: 0px 6px;
    opacity: 0.6;
}

.firstinfosig {
    flex-direction: row;
    z-index: 2;
    position: relative;
}

.firstinfosig .img {
    /* border-radius: 50%; */
    width: 120px;
    /* height: 120px; */
}

.firstinfosig .profileinfosig {
    padding: 0px 20px;
}

.firstinfosig .profileinfosig h1 {
    font-size: 1.8em;
}

.firstinfosig .profileinfosig h3 {
    font-size: 1.2em;
    color: #611f1e;
    font-style: italic;
}

.firstinfosig .profileinfosig p.bio {
    padding: 10px 0px;
    color: #5A5A5A;
    line-height: 1.2;
    font-style: initial;
}

.background_colr {
    background: rgb(255, 255, 255) !important;
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 12%,#f39695 100%) !important;
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 12%, #f39695 100%) !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 12%, #f39695 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#7ce5fa", GradientType=1) !important;
}

@keyframes animatop {
    0% {
        opacity: 0;
        bottom: -500px;
    }

    100% {
        opacity: 1;
        bottom: 0px;
    }
}

@keyframes animainfos {
    0% {
        bottom: 10px;
    }

    100% {
        bottom: -42px;
    }
}

@keyframes rotatemagic {
    0% {
        opacity: 0;
        transform: rotate(0deg);
        top: -24px;
        left: -253px;
    }

    100% {
        transform: rotate(-30deg);
        top: -24px;
        left: -78px;
    }
}