.managementContent{
    height: 115px !important;
    color: black;
}

@media (max-width: 1110.98px) { 
    .managementContent{
        height: 180px !important;
        padding: 0px !important;
        }
 }

.menus{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 22%;
    margin: 10px 18px;
}

@media (max-width: 767.98px) { 
    .menus{
        width: 100%;
    }
 }

.managementHeading{
    color: #874821;
    background: #d2dcc6;        ;
    font-weight: 600;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.managementContent:hover{
    color: orangered;
    cursor: pointer;
}

div#accordionExample {
    width: 100%;
    margin-inline: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
    color: #812147;
    background-color: #d2dcc6;  
    font-weight: bolder;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button{
    color: #812123;
    font-weight: 700;
    letter-spacing: 0.5px;
}

button.accordion-button.collapsed {
    background: #d2dcc64f;
}

@media (min-width: 1391px) and (max-width: 1404px) { 
    .managementContent{
        height: 130px !important;
    }
 }
 
@media (min-width: 1000px) and (max-width: 1391px) { 
    .managementContent{
        height: 130px !important;
    }
    .menus{
        width: 28% !important;
    }
 }

@media (min-width: 768px) and (max-width: 1000px) { 
    .managementContent{
        height: 130px !important;
    }
    .menus{
        width: 41% !important;
    }
 }