@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');

.inter {
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: 'futura-bold';
  src: local('futura-bold'),
    url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
}

.SigEltai-paragraph {
  text-align: justify;
  font-size: 18.5px;
}

.SigEltai-Green {
  color: #104E29
}

.signika-font {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
}

.annual-conference-background-button {
  background-color: #104E29 !important;
  border-style: none;
  /* width: 30%; */
}

.annual-conference-background-button1 {
  background-color: #093219 !important;
  border-style: none;
  font-size: 10px;
  margin-right: 25px;
  margin-top: -5px;
  /* width: 25%;
    height: 78px;
    margin-right: 20px; */
}

.Annual-conference-paragraph-heading {
  font-size: 18px;
  color: #104E29;
}

.annual-table-odd {
  background-color:#7A1316 !important;
  color: white !important;
  padding: 20px 25px !important;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  font-family: "Signika", sans-serif;
}

.annual-table-even {
  background-color: #104E29 !important;
  color: white !important;
  padding: 20px 25px !important;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  font-family: "Signika", sans-serif;
}

.annual-table-body {
  /* padding:20px !important; */
  /* display: flex !important;
    align-items: center !important;
    justify-content: center !important; */
  background-color: #d9d9d9 !important;
  text-align: center;
  font-family: "Signika", sans-serif;
  font-weight: 300 !important;
  font-size: 15px !important;
}

/* .table-bordered>:not(caption)>*>* {
    border-width: 3 var(--bs-border-width);
    border-left-width: 0 !important;
    border-top-width: 0 !important; */
/* }  */

.table thead th {
  border-right: 5px solid #e8f7f9;
}

.table tbody tr td {
  border-right: 5px solid #e8f7f9;
  border-bottom: 5px solid #e8f7f9;
}

.table thead th:last-child {
  border-right: none;
}

.table tbody tr td:last-child {
  border-right: none;
}

.table tbody tr:last-child {
  border-bottom: none;
}

.gap-between-header-body thead {
  border-bottom: 0;
  /* Remove the default border if needed */
}

.gap-between-header-body thead::after {
  content: "";
  display: block;
  height: 10px;
  /* Adjust the height for the desired gap */
  /* background-color: #ffff; */
}

.mobile-view-annual-conf {
  font-weight: 300;
  font-size: 22px;
  word-spacing: -1.5px;
}

.mobile-view-annual-header {
  font-size: 23px;
}

@media screen and (min-width:0px) and (max-width: 767px) {
  .annual-conference-background-button1 {
    background-color: #093219 !important;
    border-style: none;
    font-size: 10px;
    margin-right: 0px !important;
  }
}

.table th,
.table td {
  width: auto;
  /* Default width */
}

.table {
  table-layout: fixed;
  /* Ensures widths are respected */
  width: 100%;
  /* Optional: Makes table take full width of container */
}

/* Adjust column width for tablets (screens between 768px and 991px) */
@media (min-width: 768px) and (max-width: 991px) {

  .table th:nth-child(1),
  .table td:nth-child(1) {
    width: 20%;
    /* Set width of the first column to 50% */
  }

  .table th:nth-child(2),
  .table td:nth-child(2) {
    width: 50%;
    /* Set width of the second column */
  }

  .table th:nth-child(3),
  .table td:nth-child(3) {
    width: 35%
  }

  .table th:nth-child(4),
  .table td:nth-child(4) {
    width: 20%;
    /* Adjust widths of other columns */
  }

  .table th:nth-child(5),
  .table td:nth-child(5) {
    width: 30%;
  }
}

.table-responsive {
  overflow-x: auto;
}

.table-responsive::-webkit-scrollbar {
  height: 8px;
  /* Adjust scrollbar height */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #007bff;
  /* Scrollbar thumb color */
  border-radius: 4px;
}

.annual-brown-color {
  color: #7A1316
}