.cardHoverEffect {
  box-shadow: none;
  transition: box-shadow 0.25s ease-in-out 0.25s;
}

.cardHoverEffect:hover {
  box-shadow: 10px 10px 5px grey;
}

.customScroll {
  max-height: 450px;
  overflow-y: auto;
}

.customScroll {
  max-height: 400px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbars in Webkit browsers */
.customScroll::-webkit-scrollbar {
  display: none;
}



.cmtSection {
  margin-top: 7%;
}

@media (max-width: 1177.98px) {
  .cmtSection {
    margin-top: 10%;
  }
}

@media (max-width: 960px) {
  .cmtSection {
    margin-top: 15%;
  }
}

@media (max-width: 777px) {
  .cmtSection {
    margin-top: 20%;
  }
}