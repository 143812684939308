
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');
.inter {
    font-family: "Inter", sans-serif;
  }
  @font-face {
    font-family: 'futura-bold';
    src: local('futura-bold'),
         url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
  }
.signika-font {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    /* font-style: normal; */
  }
  .teacher-member-card{
    background-color: #fff;
    border:2px solid #307D50 !important ;
    position: relative;
  }

  .techer-member-image-editIcon{
    color: white;
    background-color: darkgreen;
    width: 23px;
    height: 23px;
    padding: 7px;
    position: relative;
    z-index: 1;
    justify-content: center;
    display: flex;
    top: 88%;
    border-radius: 50%;
    left: 75%;
    font-weight: 700;
  }
  .teacher-member-header{
    text-transform: uppercase;
  }
  .edit-button-green{
    color:green
  }
  .curser-to-pointer{
    cursor: pointer;
  }
  .no-bullet {
    list-style-type: none;
    padding-left: 0px !important;
  }
  