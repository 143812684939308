.subscription::placeholder {
  color: white;
  font-size: 15px;
  width: 100%;
}

.mapFrame {
  width: 400px !important;
  height: 300px;
}

/* Hide text by default */
.small-screen-text {
  display: none;
}

.small-screen-text_content {
  display: none;
  color: white;
}

/* Show text only on small screens (max-width: 767px) */
@media (max-width: 778px) {
  .small-screen-text {
    display: block;
    color: white;
    align-items: center;

  }

  .small-screen-text_content {
    display: block;
    color: white;
    align-items: center;

  }

  .small-screen-text_content {
    text-align: start;
  }
}

@media (min-width: 770px) and (max-width: 992px) {
  iframe {
    width: 700px !important;
  }
}

@media screen and (min-width:767px) and (max-width:769px) {
  .subscription::placeholder {
    font-size: 11px;
  }

  .small-screen-text_content {
    margin-left: -8pc !important;
    color: whitesmoke
  }

  .map {
    display: none !important;
  }
}

@media screen and (min-width:650px) and (max-width:766px) {

  /* @media screen and(min-width:650px) and (max-width: 766px) { */
  .small-screen-text_content {
    margin-left: -6pc !important;
  }
}



@media (min-width:572px) and (max-width: 649px) {

  .small-screen-text_content {
    margin-left: -5pc !important;
  }
}

@media (min-width:470px) and (max-width: 573px) {
  .small-screen-text_content {
    margin-left: -3pc !important;
  }
}

@media (max-width:200px) and (max-width: 468px) {
  .small-screen-text_content {
    margin-left: 0pc !important;
  }
}
.empty-none:empty{
  display:none !important;
}
.border-md{
  border:none;
}
.footer-card2{
  width:auto;
}
@media (max-width:992px){
  .border-md{
    border: 2px solid white !important;
  }
}
@media (max-width:576px){
  .footer-card2{
    width:100%;
  }
}

.footerScale{
  /* display: flex;
  justify-content: space-between; */
  padding: 31px 75px;
  flex-wrap: wrap;
}

/* @media (max-width:1247px){
  .border-md{
    border: 2px solid white !important;
  }
} */