
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');
.inter {
    font-family: "Inter", sans-serif;
  }
  @font-face {
    font-family: 'futura-bold';
    src: local('futura-bold'),
         url('../../../assets/fonts/Futura-Bold.otf') format('otf'),
  }
.signika-font {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    /* font-style: normal; */
  }
  .partner-clippath-headers{
    /* border: 1px solid green; */
    clip-path: polygon(15% 5%, 68% 3%, 73% 100%, 20% 100%);
    background-color: #134b2B;
    color: white;
    width:35%;
    padding: 1%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .partner-image{
    position: relative;
  }
  .partner-image-header{
    position: absolute;
    font-weight: 500;
    font-family: "Signika", sans-serif;
    margin-top: 36px;
  }
  .partner-image-number{
    position: absolute;
    font-weight: 500;
    font-family: "Signika", sans-serif;
    margin-top: 36px;
    margin-left: 350px;
    color: #fff;
  }
  .teacher-asso-card{
    border:2px solid ;
    border-style: none;
    width: 100%;
    height:250px;
    background-color: #fff;
    box-shadow:1px 1px 5px 5px #dcdcdc;
    /* display: flex;
    justify-content: center; */
  }
  .details-partner{
  font-size: 18px;
  }
  .our-partner-img{
    width: 70%;
    object-fit: fill !important;
  }