.singleEvent {
    margin-inline: auto;
}

.individualEvent {
    border-radius: 16px;
    float: inline-start;
    margin-right: 10px;
}

.sponser {
    color: #c40000;
}
.speaker{
    text-align: end;
}
.sponser-h6 {
    text-align: justify;
}

.speakers {
    height: 570px;
    overflow-y: scroll;
}

.speakers::-webkit-scrollbar {
    width: 0;
    /* This will hide the scrollbar */
    background: transparent;
    /* Optional: Makes scrollbar area transparent */
}

.separateEvent_content {
    text-align: justify;
    color: black;
}

.aligncard {
    padding-left: 36px
}

.event_colr {
    color: #e3baba !important
}

.event-page {
    background: linear-gradient(#F5F5F5, #f9c2b2);
}

.separateevent_title {
    font-size: xx-large;
    font-weight: 800;
    font-family: "Lora " !important;
    color: #c40000;
}

.separate_desc {
    text-align: justify;
}

.spacing {
    padding: 27px;
    margin-bottom: 20px;
}

.datecss {
    font-size: 22px;
    font-weight: 500;
}

.dateLable {
    background-color: #c40000;
    font-size: 21px;
    color: black;
    border-radius: 12px;
    width: 125px;
}

.timelable {
    background-color: #c40000;
    color: black;
    border-radius: 12px;
    width: 220px;
    padding-left: 11px;
}

@media (min-width: 320px) and (max-width: 991px) {
    .md-responsive-class {
        margin-left: 20px;
        padding-right: 55px;
        margin-top: 5px;
    }

    .md-responsive-class-1 {
        margin-bottom: 20px;
    }

    .md-responsive-class-2 {
        margin: 0px 10px 0px -10px;
    }

    .md-responsive-class-3 {
        padding-right: 32px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .md-responsive-class {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }
    .md-responsive-class-4 {
        width: 250px !important;
    }
}