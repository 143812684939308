.animated-card {
    opacity: 0;
    transform: scale(0.8);
    animation: fadeInScale 0.8s ease-out forwards;
  }
  
  @keyframes fadeInScale {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  /* style={{ width: '18rem', borderRadius: '15px' }} */
  .news-card-india-reads-containter{
    width:18rem;
    border-radius:15px;
    height: auto;
  }
  .card-img-top-news-india-reads{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .card-text-india-reads{
   background-image: linear-gradient(#7bb994,#104e29);
    color:white
  }