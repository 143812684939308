.about-hr {
  border: none;
  height: 2px;
  background-color: #7a1316;
  width: 100%;
  margin-top: 35px;
}

.counter-shape1 {
  animation: rotate-shape 10s linear infinite alternate;
}

.counter-shape2 {
  animation: translate-shape 8s linear infinite alternate;
}

@keyframes rotate-shape {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes translate-shape {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50px);
  }
}

.counter-numb {
  animation: pulse 1.5s ease-in-out infinite alternate;
  top: -10px;
  position: relative;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.floating-object {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: float 6s ease-in-out infinite alternate;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-20px) rotate(180deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

.event-counter-section {
  position: relative;
  /* background-color: #f7f7f7;  */
  color: #333;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 575.98px) {
  .event-counter-section {
    padding-top: 9px;
  }
}

.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section-title h2 {
  font-size: 25px;
  color: #7A1316;
  ;
  transition: color 0.3s ease;
}

.section-title h2:hover {
  color: #ff5722;
}

/* .counter-item {
    padding: 20px;
    background: #fff;
    text-align: center;
    margin: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease; 
} */

.counter-item {
  width: 150px !important;
  height: 159px;
  border: 2px dotted black;
  text-align: center;
  padding: 20px;
  border-radius: 50%;
}

.counter-item:hover {
  transform: scale(1.05);
}

.counter-item h1 {
  font-size: 30px;
  margin-bottom: 1px;
  font-weight: 800;
  border-radius: 50%
}

.counter-item span {
  font-size: 18px;
  font-weight: 700;
  /* text-transform: uppercase; */
}

.color1 {
  color: #40A798;
}

.color2 {
  color: #B84A39;
}

.color3 {
  color: #27AA80;
}



.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  margin-top: 20px;
  text-align: center;
}

.abt {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 600px) {
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .content {
    margin-top: 0;
    margin-left: 20px;
    text-align: left;
  }
}

.star {
  background-color: transparent;
  border-radius: 50%;
  animation: twinkle 5s linear infinite;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
    0 0 20px rgba(255, 255, 255, 0.6),
    0 0 30px rgba(255, 255, 255, 0.4),
    0 0 40px rgba(255, 255, 255, 0.2);
}

@keyframes twinkle {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.floating-object.star {
  width: 10px;
  height: 10px;
  animation: float 6s ease-in-out infinite alternate, rotate 10s linear infinite alternate;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* .aboutsection-container{
    background-color: #f7f7f7;
} */

.section-title h2 {
  transition: color 0.3s ease, transform 0.3s ease;
  /* Add transition for smoother color change */
}

.section-title h2:hover {
  color: #ff5722;
  transform: scale(1.05);
  /* Scale up title on hover */
}

.heading {
  /* font-family: "Lora " !important; */
  font-family: 'futura-bold';
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
  position: relative;
  font-weight: 800;
  font-style: italic;
}

.heading::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: #333;
}

.animated {
  animation: slideIn 1s forwards;
  color: #333;
  /* Change text color */
  font-family: 'futura-bold';
  font-size: 24px;
  /* Change font size */
  font-weight: bold;
  /* Change font weight */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Add text shadow */
}

.counter-item {
  position: relative;
  padding: 0px;
  background: #fff;
  text-align: center;
  margin: 22px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.counter-item::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 3px dotted #0a0a0a;
  border-radius: 50%;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

/* .counter-item:hover::before {
    animation: borderMove 2s linear infinite;
} */

@keyframes borderMove {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.counter-item:hover {
  transform: scale(1.1);
}

/* Add this CSS to your existing stylesheet */
.floating-object.star {
  position: absolute;
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
  /* Adjust size as needed */
  background-color: yellow;
  /* Color of the star */
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: floatAnimation 5s infinite linear;
  z-index: 12999999;
}

@keyframes floatAnimation {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(50px, 50px);
    /* Adjust the maximum translation */
  }

  100% {
    transform: translate(0, 0);
  }
}

.abt-hr {
  border: none;
  height: 2px;
  color: #e41d1d;
  background-color: #e41d1d;
  width: 80%;
  margin-top: 53px;
}

.announcement-hr {
  border: none;
  height: 2px;
  color: #7a1316;
  background-color: #7a1316;
  flex-grow: 1;
  /* Allows the line to expand and fill remaining space */
  margin-left: 20px;
  /* Adjust margin as needed to position correctly */
  margin-right: 20px;
  /* Adjust margin as needed to position correctly */
}

.abt-bg {
  background-color: #EFFDF5;
}

.abt-text {
  line-height: 1.9;
  text-align: justify;
  font-size: 23px;
  padding: 0px 88px;
}

@media (max-width: 575.98px) {
  .abt-text {
    font-size: 15px;
    padding: 0px 52px
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .sub-title {
    font-size: 35px !important;
  }

  .section-title h2 {
    font-size: 21px !important;
  }
}

.sub-title {
  font-size: 40px;
  font-weight: 700;
}

.title {
  font-size: 20px;
  color: #7A1316;
}

.member-config {
  color: #F15C5C;
}

.chapter-config {
  color: #B84A39;
}

.sig-config {
  color: #CC435F;
}

@media screen and (max-width: 430px) {
  .counter-item {
    width: 50%;
  }
}

@media screen and (max-width: 540px) {
  .counter-item {
    width: 50%;
  }
}

@media screen and (max-width: 320px) {
  .counter-item {
    width: 50%;
  }
}

@media screen and (min-width: 541px) and (max-width: 820px) {
  .counter-item {
    width: 22%;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 821px) and (max-width: 912px) {
  .counter-item {
    width: 22%;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 913px) and (max-width: 1024px) {
  .counter-item {
    width: 22%;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  /* .counter-item {
      width: 21%;
  } */
}

/* Section title centering for multiple device widths */
@media screen and (min-width: 768px) and (max-width: 1024px),
(min-width: 820px) and (max-width: 912px),
(min-width: 540px) and (max-width: 853px),
(min-width: 768px) and (max-width: 853px) {
  .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}


/* Container Styling */
.eltai-section {
  padding: 20px;
  background-color: #d2dcc6;
  font-family: Arial, sans-serif;
}

/* Statistics Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* gap: 20px; */
  margin-bottom: 30px;
}

.stat-box {
  /* background: #ffffff; */
  border: 2px solid #104e29;
  /* border-radius: 5px; */
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-box h2 {
  font-weight: bold;
  font-size: 28px;
  color: #104e29;
  margin-bottom: 10px;
}

.stat-box p {
  font-size: 18px;
  font-weight: bold;
  color: #104e29;
}


.about_content {
  /* max-width: 800px; Adjust the container width as needed */
  margin: 0 auto;
  line-height: 1.6;
}

.about_video {
  float: right;
  margin: 5px 0px 10px 14px;
  width: 50%;
  height: auto;
  /* Maintain aspect ratio */
}

.about_txt {
  font-family: 'Poppins';
  text-align: justify;
}

.about_heading {
  text-align: start;
  color: #104e29;
  font-size: 25px;
  font-weight: bold;
  margin: 28px 0px;
}

@media (min-width:500px) and (max-width:845px) {
  .stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
    /* gap: 5px; */
  }

  .stat-box p {
    font-size: 14px;
  }

  .stat-box h2 {
    font-size: 25px;
  }

  .stat-box {
    padding: 5px
  }

}

@media (min-width:345px) and (max-width:500px) {
  .stats-grid {
    gap: 7px;
  }

  .stats-grid>.stat-box:last-child:nth-child(odd) {
    grid-column: 1 / -1;
    justify-self: center;
    width: 240px
  }
}

@media (max-width:346px) {
  .stats-grid {
    gap: 7px;
  }
}

@media (min-width:1800px){
  .about_video { 
    width: 40%;
    height: 325px;
  }
}
@media (max-width:576px){
  .about_video {
    float: none;
    margin: 10px;
    width: 95%;
    height: auto;
  }
}