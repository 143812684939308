
.membershippage-paragraph{
    font-size: 18px;
    font-family: "Signika", sans-serif;
    font-weight: 350;
    text-align: justify;
}

.membershippage-TopicTitles{
    font-size: 19px;
    font-weight: bold;
    font-family: "Signika", sans-serif;
    font-weight: 600 !important;
    color: #233e2d;
}

.membershippage-CategoryTitles{
    font-size: 19px;
    font-weight: bold;
    font-family: "Signika", sans-serif;
}

.membershippage-note{
    background-color:antiquewhite ;
}

.membership-cardheadings{
  font-size: 18px;
}


.plans-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.plan-card {
  background-color: #dbe4ba;;
  border: 1px solid black;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 250px;
  margin-right: 0px;
  margin-left: 0px;
}

.plan-card h3 {
  margin:0px;
  font-family: "Signika", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.plan-card p {
  margin:0px !important; 
  font-family: "Signika", sans-serif;
  margin: 5px 0;
  font-size: 16px;
}

.price {
  margin:0px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Signika", sans-serif;
}

.original-price {
  margin:0px;
  font-family: "Signika", sans-serif;
  text-decoration: line-through;
  color: rgb(181, 70, 70);
  font-size: 16px;
  margin-right: 10px;
  font-weight: normal;
}

.discount {
  margin:0px;
  font-family: "Signika", sans-serif;
  font-size: 16px;
  font-weight: normal;
}

/* Media Query for Tablets */
@media (max-width: 768px) {
  .plan-card {
    width: 200px;
  }

  .price {
    font-size: 18px;
  }

  .plan-card h3 {
    font-size: 16px;
  }
}

/* Media Query for Mobile */
@media (max-width: 480px) {
  .plan-card {
    width: 90%; /* Full width with margins */
    margin: 0 auto;
  }

  .price {
    font-size: 16px;
  }

  .plan-card h3 {
    font-size: 14px;
  }

  .plan-card p {
    font-size: 12px;
  }
}