/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
.event-font-color{
    color:#0072bc;
    font-family: "Lora " !important;
}
.font-family1{
    font-family:"Lora " !important;
}
.event-subtitle-box{
    border-radius: 25px;
}
.view-event-title-card{
    border-radius: 25px;
}
.view-event-webinar-image{
    object-fit:cover;
    width: 100%;
    height: fit-content;
    
}
.view-event-button{
    width: 100%;
    height: 50px;
    background-color: #0072bc;
    border-radius: 10px;
    color:#fff;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
    align-items: center;
}
.view-event-bgcolor{
    background-color: #0072bc;
    color:#ffff;
    
}
.view-event-icons{
    font-size: 10px;
}
.view-event-list-of-icons {
    width: 100%;
   border :2px solid #0072bc
}
.view-event-icon-details{
    font-size: 10px;
    margin-top: -20%;
}
.view-event-icon-details-title{
    font-size: 10px;
    margin-top: -20%;
}
.footer-view-event-collabarate{
    word-spacing: 5px;
}
.view-event-container{
    background-image: url("../../../../public/assets/images/Event/event-bg-color.png");
    width: 100%;
    height: auto;
    background-position: center;
     background-repeat: no-repeat; 
     background-size: cover;
   
}
.staff-details{
    font-size: 16px;
    font-weight: 800;
}
.staff-view-event{
    width: 100%;
}
.view-event-staffdetails-container{
    width:100;
    background-color: #0072bc;
    display: flex;
    justify-content: center;
    align-items: center;
}