/* .parallax {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0);
  } */
  .parallax {
    opacity: 0;
    transition: opacity 3s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(20px); 
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0); 
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .parallax:nth-child(odd) {
    animation: fadeInRight 1s ease-out;
  }
  
  .parallax:nth-child(even) {
    animation: fadeInLeft 1s ease-out;
  }
  
  .parallax:nth-child(3n) {
    animation: fadeInUp 1s ease-out;
  }
  
  
  /* .parallax-container{
    position: relative;
    top: -50px;
  } */