.banner_heading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 122px;
}


/* ul#\:rp\: {
  display: flex !important;
  flex-direction: column !important;
  left: 2px;
} */


.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination .active {
  font-weight: bold;
  color: blue;
}

.itemsperpage {
  width: 200% !important;
  /* padding: 10px !important; */
  height: 43px;
  left: -66px;
}

.goPage {
  /* padding: 15px; */
  background-color: brown;
  color: white;
}

@media (min-width: 992px) and (max-width: 1098px) {
  .search-input {
    width: 100px;
  }

  .itemsperpage {
    width: 200%;
  }
}

@media (max-width: 991.98px) { 
  .itemsperpage {
    width: 295% !important;
  }
}